import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { RiCloseLine } from "react-icons/ri";
import { PiUserCircleFill } from "react-icons/pi";
import { IoCartOutline } from "react-icons/io5";
import { CiBookmark } from "react-icons/ci";
import { RxCounterClockwiseClock } from "react-icons/rx";
import { IoMdAlert } from "react-icons/io";
import { IoCallOutline } from "react-icons/io5";
import { FaTelegram } from "react-icons/fa6";
import { MdLogout } from "react-icons/md";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/actions/auth.action";

const Sidebar = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("user"));
  const handleLogout = async () => {
    await dispatch(logout());
    setTimeout(() => {
      navigate("/sign-in");
    }, 1000);
  };
  return (
    <>
      {isOpen && (
        <div
          className="fixed top-0 right-0 h-full w-full bg-[#93939399] z-[999]"
          onClick={onClose}
        ></div>
      )}
      <div
        className={`fixed top-0 right-0 h-full w-[20rem] overflow-y-auto bg-[#FFF] z-[1000] transition-transform transform ${isOpen ? "translate-x-0" : "translate-x-full"
          }`}
      >
        <div className="d-flex flex-column justify-content-between h-full">
          <div>
            <div className="flex justify-between items-center p-4 ">
              <a className="navbar-brand" href="#">
                <img
                  src="/assets/images/Logo/koinpr-Logo-Dark.png"
                  alt="koinpr logo"
                />
              </a>
              <button onClick={onClose} className="text-[#000000]">
                <RiCloseLine className="w-6 h-6" />
              </button>
            </div>
            <div className="flex gap-[14px] bg-[#F9F9F9] py-[21px] px-4">
              <div className="w-[40px] h-[40px] bg-[#3053A6] rounded-full flex items-center justify-center text-white text-lg">
                {user?.fullname?.charAt(0).toUpperCase()}
              </div>
              <div>
                <p className="font-[510] text-[16px] leading-[19.2px] text-[#0A0A0A] !mb-0">
                  {user?.fullname}
                </p>
                <span className="font-[400] capitalize text-[13px] leading-[19.5px] text-[#787878]">
                  {user?.userType}
                </span>
              </div>
            </div>
            <div className="px-4 my-[26px]">
              <div className="suggest_btn">
                <button className="font-[590] text-[16px] leading-[19.2px] text-[#000000] flex items-center gap-2 px-[20px] py-[12px]">
                  <img src="/assets/images/MarketPlace/group.png" alt="" />{" "}
                  Hire Conversions Expert
                </button>
              </div>
            </div>
            <ul className="mt-4 px-4">
              <li className="mb-3">
                <Link
                  className="font-[510] text-[13px] leading-[15.6px] text-[#434343] no-underline flex gap-2 items-center"
                  to="#"
                >
                  {/* <PiUserCircleFill className="w-[24px] h-[24px] text-[#434343]" />{" "} */}
                  <img src="/assets/images/MarketPlace/account.svg" alt="account" className="text-[#434343]" />
                  My Account{" "}
                  <IoMdAlert className="w-[16px] h-[16px] text-[#eb5757]" />
                </Link>
              </li>
              <li className="mb-3">
                <Link
                  className="font-[510] text-[13px] leading-[15.6px] text-[#434343] no-underline flex gap-2 items-center"
                  to="/my-cart"
                >
                  <IoCartOutline className="w-[24px] h-[24px] text-[#434343]" />{" "}
                  My Cart
                </Link>
              </li>
              <li className="mb-3">
                <Link
                  className="font-[510] text-[13px] leading-[15.6px] text-[#434343] no-underline flex gap-2 items-center"
                  to="/Package"
                >
                  <IoCartOutline className="w-[24px] h-[24px] text-[#434343]" />{" "}
                  Package
                </Link>
              </li>
              <li className="mb-3">
                <Link
                  className="font-[510] text-[13px] leading-[15.6px] text-[#434343] no-underline flex gap-2 items-center"
                  to="/Marketplace"
                >
                  <IoCartOutline className="w-[24px] h-[24px] text-[#434343]" />{" "}
                  Marketplace
                </Link>
              </li>
              {/* <li className="mb-3">
                <Link
                  className="font-[510] text-[13px] leading-[15.6px] text-[#434343] no-underline flex gap-2 items-center"
                  to="#"
                >
                  <CiBookmark className="w-[24px] h-[24px] text-[#434343]" /> My
                  Bookmarks
                </Link>
              </li> */}
              {/* <li className="mb-3">
                <Link
                  className="font-[510] text-[13px] leading-[15.6px] text-[#434343] no-underline flex gap-2 items-center"
                  to="#"
                >
                  <RxCounterClockwiseClock className="w-[24px] h-[24px] text-[#434343]" />
                  Order History
                </Link> */}
              {/* </li> */}
            </ul>
          </div>
          <div>
            {/* <div className="px-4 mt-4">
              <div className="roadmap mt-[20px] py-[16px] px-[20px]">
                <div>
                  <h1 className="font-[590] text-[20px] leading-[24px] text-[#FFFFFF]">
                    Marketing Roadmap? <br />
                    It’s on us.
                  </h1>
                </div>
                <div className="mt-[20px]">
                  <button className="flex items-center font-[510] py-[5px] px-[8px] bg-[#FFFFFF] text-[13px] rounded-[4px] leading-[15.51px] gap-[4px] text-[#1D1D1D] ">
                    <IoCallOutline className="text-[#000000] text-[24px]" />{" "}
                    Schedule call
                  </button>
                  <button className="font-[510] flex items-center gap-[4px] text-[13px] pt-[18px] pb-[10px]  leading-[15.51px] text-[#FFFFFF]">
                    {" "}
                    <FaTelegram className="text-[#fff] text-[24px]" />
                    Text on Telegram
                  </button>
                </div>
              </div>
            </div> */}

            <div className="border-[1px] border-[#DBDBDB] my-[19px]"></div>

            <div
              className="font-[510] text-[15px] leading-[15.6px] text-[#434343] flex gap-[6.5px] items-center px-4 mb-[19px]"
              onClick={handleLogout}
            >
              <MdLogout className="text-[#434343] w-[18px] h-[18px] " />
              Log out
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
