import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { FaSquarePlus } from "react-icons/fa6";
import BannerExample from "./BannerExample";
import useAddCart from "../../hooks/useAddCart";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { fetchCartData } from "../../redux/actions/cart.actions";

const PressRelease = ({ state, isAdvertiser }) => {
  const cartItems = useSelector((state) => state.cart.cartItems);
  const { loading, addToCart } = useAddCart();
  const sectionRefs = useRef({});

  const navigate = useNavigate();
  // const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleAddToCart = async (itemId) => {
    try {
      await addToCart("addOns", itemId);
      dispatch(fetchCartData());
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };
  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );

  const scrollToSection = () => {
    if (state?.targetId && sectionRefs.current[state?.targetId]) {
      sectionRefs.current[state?.targetId].scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToSection(); // Scroll on component mount or targetId change
  }, [state?.targetId]);

  const checkItemExistInCart = useCallback(
    (itemId) => {
      if (!cartItems) return false;

      const allItems = [
        ...(cartItems.adminPackages || []),
        ...(cartItems.offerings || []),
        ...(cartItems.addOn || []),
        ...(cartItems.packages || []),
      ];

      return allItems.some((item) => item._id === itemId || item.id === itemId);
    },
    [cartItems]
  );

  

  return (
    <>
      {state?.contentOfferings?.map((content, idx) => {
        const isExist = checkItemExistInCart(content._id);
        return (
          <React.Fragment key={content?._id}>
            <div
              ref={(el) => (sectionRefs.current[content._id] = el)}
              className="bg-white mb-4 overflow-hidden shadows rounded-lg border-1 border-[#DBDBDB]"
            >
              <div className="p-4">
                <div className="d-flex items-center justify-between mb-3">
                  <h3 className="flex items-center gap-2 md:text-[24px] text-[20px] font-[590] mb-0">
                    <img
                      src="/assets/images/expanded/newslogo.png"
                      alt="newslogo"
                      className="w-[40px]"
                    />
                    {content?.typeId?.name}
                  </h3>
                  <span className="md:text-xl text-base font-[590]">
                    ${content?.mediaKitprice}
                  </span>
                </div>

                <table className="table table-bordered rounded table-striped">
                  <thead>
                    <tr>
                      {/* <th scope="col" className="w-[200px]">
              Feature Name
            </th>
            <th scope="col">Available</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {content?.features?.map((feature, index) => {
                      return (
                        <tr key={index}>
                          <td className="capitalize">
                            {feature?.featureType?.name}
                          </td>
                          <td className="text-center">{feature?.value}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="!bg-[#F5F5F5] px-4 card-footer border-0 p-2 d-flex justify-between items-center">
                <button
                  className="no-underline cursor-pointer text-blue-500 font-[590] text-[13px]"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasBottom"
                >
                  See Example
                </button>

                {isAdvertiser && !isExist ? (
                  <>
                    {" "}
                    <span className="font-[590] text-[13px] flex items-center gap-1">
                      Add to cart
                      <FaSquarePlus
                        className="text-2xl cursor-pointer"
                        onClick={() => {
                          if (!isAuthenticated) {
                            navigate("/sign-in");
                            return;
                          }
                          if (!loading) {
                            handleAddToCart(content?._id);
                          }
                        }}
                      />
                    </span>
                  </>
                ) : (
                  <>
                    {" "}
                    <span className="font-[590] text-[13px] flex items-center gap-1">
                      Already in cart
                      <FaCheckCircle className="text-2xl cursor-pointer text-green-500" />
                    </span>
                  </>
                )}
              </div>
            </div>
            <BannerExample state={content} />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default PressRelease;
