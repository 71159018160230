import React from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom'

const Congratulations = () => {
    const navigate = useNavigate()
    const storeUserData = useSelector((state) => state.authentication.user);
  const user = JSON.parse(localStorage.getItem("user")) || storeUserData;

  const handlenavigate = () => {
    if(user.userType === "publisher") {
        navigate('/marketplace')
    } else {
        navigate('/dashboard')
    }
  }
    return (
        <div className='congratulation flex items-center justify-center min-h-screen'>
            <div>
                <div className='bg-white rounded-xl py-4 px-[60px] text-center'>
                    <img src='/assets/images/account/Completed.png' className='w-[100px] mx-auto mb-3' alt='completed' />
                    <p>Congratulations!</p>
                    <p className='mb-0'>Your account is under Review, we have sent a mail for confirmation.</p>

                </div>
                <button onClick={() => {
                    handlenavigate()
                }} className='block py-[15px] w-full px-[20px] rounded-lg bg-black-color no-underline text-white text-center mt-3'>Done</button>
            </div>
        </div>
    )
}

export default Congratulations
