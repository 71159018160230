import React from "react";
import { PiStarFourFill } from "react-icons/pi";


const Testimonials = () => {
  const data = [
    { id: 1, img: '/assets/images/Package/user.png', name: 'Cédric van Ravesteijn', des: 'Framer has helped me to scale my agency by being able to make websites in a very efficient and creative way.' },
    { id: 2, img: '/assets/images/Package/user.png', name: 'Melody O.', des: "Framer offers an unparalleled design experience with its seamless integration of design and development. It's user-friendly, efficient, and promotes creativity, making it an essential tool for anyone looking to quickly bring their ideas to life." },
    { id: 3, img: '/assets/images/Package/user.png', name: 'Thaer Swailem', des: 'Framer has helped me to design great websites with half the effort.' },
    { id: 4, img: '/assets/images/Package/user.png', name: 'Lenderson M.', des: "Framer offers an unparalleled design experience with its seamless integration of design and development. It's user-friendly, efficient, and promotes creativity, making it an essential tool for anyone looking to quickly bring their ideas to life." },
    { id: 5, img: '/assets/images/Package/user.png', name: 'Haris Sulaiman', des: "Framer has helped me improve the SEO of @cloodot by 250% since July when we first launched with Framer." },
    { id: 6, img: '/assets/images/Package/user.png', name: 'Rico Trevisan', des: "Absolutely excellent. A good prompt will generate a whole page: sections, colors, fonts, copy, graphics, everything. Wow!" },
    { id: 7, img: '/assets/images/Package/user.png', name: 'Cristian Nobile', des: "Framer has helped me to create much more functional no-code sites for my customers due to the increased speed and freedom to create the UI." },
    { id: 8, img: '/assets/images/Package/user.png', name: 'Madhusudhan', des: "I used to be a Webflow user. I relied on Webflow for all my freelance projects. However, when Framer was launched, I was amazed by its smooth performance. Typically, it took me around 5 days to complete websites, but with Framer, I did it in just 2 days. @Framer is impressive! 🔥" },
    { id: 9, img: '/assets/images/Package/user.png', name: 'Mitchell B.', des: "The incredibly control you have over everything in a drag and drop interface makes it fun to create for the web again. It's also incredibly quick to go from" },
    { id: 10, img: '/assets/images/Package/user.png', name: 'Andreas Blomqvist', des: "Framer has helped me turn ideas into high-quality websites with 10x the speed" },
    { id: 11, img: '/assets/images/Package/user.png', name: 'Yã izidoro', des: "Framer help me to do websites in 3 days, without any code 😅" },
    { id: 12, img: '/assets/images/Package/user.png', name: 'Timothy Upai Lindris', des: "My experience with Framer has been nothing short of exceptional. It has elevated my design process, empowered collaboration, and helped me deliver outstanding results. If you're a designer looking to level up your skills and create stunning interactive designs, I highly recommend giving Framer a try." }
  ];

  return (
    <section className="py-16 bg-gray-900">
      <div className="container mx-auto">
        <div className="text-center">
          <span className="md:text-base text-[12px] inline-flex mb-3 justify-center items-center gap-2 text-white border-1 border-[#727272] py-[8px] px-[18px] rounded-xl"><PiStarFourFill />Success Stories</span>
          <h2 className="md:text-3xl text-2xl font-bold text-white mb-5">
            Our Clients Love Us
          </h2>
        </div>
        <div className="columns-1 sm:columns-2 lg:columns-4 gap-10">
          {data.map((res) => (
            <div key={res.id} className="bg-gray-800 rounded-[16px] p-[20px] text-white shadow-md mb-10 break-inside-avoid">
              <div className="flex items-center mb-4">
                <div className="w-10 h-10 rounded-full flex items-center justify-center">
                  <img
                    src={res.img}
                    alt={res.name}
                    className="w-8 h-8 rounded-full"
                  />
                </div>
                <div className="ml-4">
                  <h3 className="md:text-xl text-base font-bold mb-0">{res.name}</h3>
                </div>
              </div>
              <p className="text-gray-300">{res.des}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
