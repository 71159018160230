import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Link } from "react-router-dom";

const SideBar = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [offeringsOpen, setOfferingsOpen] = useState(false);
  const [billingOpen, setBillingOpen] = useState(
    !(user?.userType === "publisher")
  );

  return (
    <div className="bg-[#0A0A0A] hidden md:block  w-[252px] fixed left-[0] h-[100vh] border-t-2 border-[#DBDBDB]">
      <div className="container">
        <ul>
          {user?.userType === "publisher" && (
            <>
              <li className="flex mt-[0] pt-[30px] items-center gap-[12px] py-[0px] px-[12px] font-[590] text-[16px] leading-[19.2px] text-[#FFFFFF]">
                <Link
                  to="/Dashboard"
                  className="flex items-center gap-[12px] no-underline text-white"
                >
                  <img
                    src="/assets/images/Home-Page/dashboard.png"
                    alt="dashboard"
                  />
                  Dashboard
                </Link>
              </li>
              <li className="flex flex-col">
                <div
                  onClick={() => setOfferingsOpen(!offeringsOpen)}
                  className="flex mt-[0] pt-[30px] items-center gap-[12px] py-[0px] px-[12px] font-[590] text-[16px] leading-[19.2px] text-[#FFFFFF] cursor-pointer"
                >
                  <img src="/assets/images/Home-Page/offer.png" alt="offer" />{" "}
                  Offerings
                  {offeringsOpen ? (
                    <IoIosArrowUp className="ml-auto" />
                  ) : (
                    <IoIosArrowDown className="ml-auto" />
                  )}
                </div>
                {offeringsOpen && (
                  <ul className="ml-[45px]">
                    <Link
                      className="nav-link !text-[#fff]"
                      aria-current="page"
                      to="/my-offerings"
                    >
                      <li className="mt-[0] pt-[20px] font-[510] text-[16px] leading-[17.2px] text-[#DBDBDB]">
                        My Offerings
                      </li>
                    </Link>
                    <Link
                      className="nav-link !text-[#fff]"
                      aria-current="page"
                      to="/my-package"
                    >
                      <li className="mt-[0] pt-[20px] font-[510] text-[16px] leading-[17.2px] text-[#DBDBDB]">
                        My Packages
                      </li>
                    </Link>
                  </ul>
                )}
              </li>
              <li className="flex mt-[0] pt-[30px] items-center gap-[12px] py-[0px] px-[12px] font-[590] text-[16px] leading-[19.2px] text-[#FFFFFF]">
                <img src="/assets/images/Home-Page/report.png" alt="report" />{" "}
                Reports
              </li>
            </>
          )}
          <li className="flex flex-col">
            <div
              onClick={() => setBillingOpen(!billingOpen)}
              className="flex mt-[0] pt-[30px] items-center gap-[12px] py-[0px] px-[12px] font-[590] text-[16px] leading-[19.2px] text-[#FFFFFF] cursor-pointer"
            >
              <img src="/assets/images/Home-Page/billing.png" alt="billing" />{" "}
              Billing
              {billingOpen ? (
                <IoIosArrowUp className="ml-auto" />
              ) : (
                <IoIosArrowDown className="ml-auto" />
              )}
            </div>
            {billingOpen && (
              <ul className="ml-[40px]">
                <Link
                  className="nav-link !text-[#fff]"
                  aria-current="page"
                  to="/my-billing"
                >
                  <li className="mt-[0] pt-[20px] font-[510] text-[16px] leading-[17.2px] text-[#DBDBDB]">
                    Order History
                  </li>
                </Link>
                <Link
                  className="nav-link !text-[#fff]"
                  aria-current="page"
                  to="/payment-history"

                  
                >
                  <li className="mt-[0] pt-[20px] font-[510] text-[16px] leading-[17.2px] text-[#DBDBDB]">
                    Transaction History
                  </li>
                </Link>
              </ul>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
