import React from 'react';
import { Link } from 'react-router-dom';

const PackageFooter = () => {
    return (
        <div className='package_footer'>
            <div className='bg-[#06101B] md:py-[60px] py-[40px]'>
                <div className="container">
                    <div className="row text-white items-center">
                        <div className="col-md-7">
                            <h1 className='mb-0 lg::text-[54px] md:text-[35px] text-[26px] text-md-start text-center'>Ready To Submit Your First Press Release?</h1>
                        </div>
                        <div className="col-md-5 text-end md:mt-0 mt-3">
                            <Link to="#" className='bg-white py-[12px] mb-3 font-[510] px-[20px] rounded-lg no-underline text-black inline-block md:w-auto w-[100%] text-center'>Let's Connect</Link>
                            <p className='mb-0 findNeed-center'>We’re here to help!</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='md:px-[48px] px-[12px] pt-[50px] pb-[20px]'>
                <div className="d-lg-flex justify-between md:pb-[80px] pb-[50px] border-b border-black">
                    <div className="logo"><a href="/Dashboard"><img src="/assets/images/logo.png" alt="" /></a></div>
                    <ul className='d-flex justify-content-md-start justify-content-between items-center lg:my-auto !my-[20px]'>
                        <li><a href="#" className='no-underline font-[510] md:text-base text-[13px] text-black pe-md-4 pe-sm-2 pe-1'>About Us</a></li>
                        <li><a href="#" className='no-underline font-[510] md:text-base text-[13px] text-black pe-md-4 pe-sm-2 pe-1'>Contact Us</a></li>
                        <li><a href="#" className='no-underline font-[510] md:text-base text-[13px] text-black pe-md-4 pe-sm-2 pe-1'>FAQs</a></li>
                        <li><a href="#" className='no-underline font-[510] md:text-base text-[13px] text-black pe-md-4 pe-sm-2 pe-1'>Blog</a></li>
                        <li><a href="#" className='no-underline font-[510] md:text-base text-[13px] text-black'>Support</a></li>
                    </ul>
                    <div className='d-flex items-center gap-3'>
                        <a href="#"><img src="/assets/images/Package/linkedin.png" alt="social" /></a>
                        <a href="#"><img src="/assets/images/Package/twitter.png" alt="social" /></a>
                        <a href="#"><img src="/assets/images/Package/youtube.png" alt="social" /></a>
                    </div>
                </div>
                <div className='d-flex md:flex-row flex-col md:gap-3 gap-2 justify-center mt-4 items-center'>
                    <span className='md:text-base text-[14px]'>© 2024 Workshore. All rights reserved.</span>
                    <div className='flex justify-between items-center md:gap-4 gap-2'>
                        <a href="#" className='text-black md:text-base text-[13px] underline-offset-4'>Privacy Policy</a>
                        <a href="#" className='text-black md:text-base text-[13px] underline-offset-4'>Terms and Conditions</a>
                        <a href="#" className='text-black md:text-base text-[13px] underline-offset-4'>Cookie Policy</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PackageFooter;