import { authApi } from "../../api";

export const FETCH_CART_DATA_SUCCESS = "FETCH_CART_DATA_SUCCESS";
export const FETCH_CART_DATA_FAILURE = "FETCH_CART_DATA_FAILURE";

export const fetchCartData = () => {
  return async (dispatch) => {
    try {
      const response = await authApi.get(`/carts/get`);
     
      dispatch({
        type: FETCH_CART_DATA_SUCCESS,
        payload: response.data.cart,
      });
    } catch (error) {
      dispatch({
        type: FETCH_CART_DATA_FAILURE,
        error: error.message,
      });
    }
  };
};
