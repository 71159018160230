import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { TiDelete } from "react-icons/ti";
import { FaRegFileAlt } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { Formik } from "formik";
import { MdDelete } from "react-icons/md";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { LuPlus } from "react-icons/lu";
import { addOfferingDataForm } from "../../../redux/actions/offering.action";
import { authApi } from "../../../api";
import toast from "react-hot-toast";

const ContentOffering = ({
  offeringList,
  // features,
  setFeaturesList,
  featuresList,
  handleFetchFeatures,
  handleDeleteOffering,
  index,
  handleDataEditClose = () => {},
}) => {
  const [open, setOpen] = useState(false);
  const [addFeature, setAddFeature] = useState(false);
  const [AddFeatureData, setAddFeatureData] = useState({});
  const { offeringData } = useSelector((state) => state.offerings);
  const {
    offeringData: { contentOfferings = [] },
  } = useSelector((state) => state.offerings);
  const [formData, setFormData] = useState({
    ...contentOfferings[index],
    typeId:
      contentOfferings[index]?.typeId?._id ||
      contentOfferings[index]?.typeId ||
      "",
    offeringName: contentOfferings[index]?.offeringName,
    mediaKitprice: contentOfferings[index]?.mediaKitprice,
    mediaDiscountedKitPrice: contentOfferings[index]?.mediaDiscountedKitPrice,
    features: featuresList?.map((feature) => {
      const matchedFeature = contentOfferings[index]?.features?.find(
        (i) => i?.featureType === feature?._id
      );

      return matchedFeature ? { ...matchedFeature } : undefined;
    }),
    featuresPreview: featuresList?.map((feature) => {
      let name;
      const matchedFeature = contentOfferings[index]?.features?.find((i) => {
        if (i?.featureType === feature?._id) {
          name = feature?.name;
          return true;
        }
        return false;
      });

      return matchedFeature ? { ...matchedFeature, name } : undefined;
    }),
  });

  const dispatch = useDispatch();

  const inputRef = useRef(null);

  const handleOpenModel = (index) => {
    setOpen(index);
  };

  const handleCloseModel = () => {
    setOpen(false);
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  async function addNewFeature(data, setFieldValue, values) {
    try {
      const res = await authApi.post("/features", data);

      const updatedFeatures = [...formData?.features];
      const updatedFeaturesPreview = [...formData?.featuresPreview];
      const totalLength = featuresList?.length;

      updatedFeatures[totalLength] = {
        featureType: res.data.feature._id,
        checked: true,
        value: data?.value,
      };
      updatedFeaturesPreview[totalLength] = {
        featureType: res.data.feature._id,
        id: res.data.feature._id,
        name: data?.name,
        checked: true,
        value: data?.value,
      };

      setFieldValue("featuresPreview", updatedFeaturesPreview);
      setFieldValue("features", updatedFeatures);
      setFormData((prev) => {
        return {
          ...(prev || {}),
          features: updatedFeatures,
          featuresPreview: updatedFeaturesPreview,
        };
      });

      setFeaturesList((prev) =>
        (prev || []).map((item, idx) =>
          index === idx
            ? [
                ...item,
                {
                  ...res.data.feature,
                },
              ]
            : item
        )
      );
      setAddFeature(false);
      toast.success("Feature Added Successfully");
    } catch (error) {
      console.log({ error });

      toast.error("Failed to add feature");
    }
  }

  useEffect(() => {
    if (
      !contentOfferings[index]?.typeId?._id &&
      !contentOfferings[index]?.typeId &&
      !formData?.typeId &&
      !formData?.typeId?._id
    )
      return;
    handleFetchFeatures(
      contentOfferings[index]?.typeId?._id ||
        contentOfferings[index]?.typeId ||
        formData?.typeId ||
        formData?.typeId?._id,
      index
    );
  }, [contentOfferings[index]]);

  useEffect(() => {
    setFormData((prev) => {
      return {
        ...prev,
        features:
          featuresList
            ?.map((feature) => {
              const matchedFeature = formData?.features?.find(
                (i) => i?.featureType === feature?._id
              );

              return matchedFeature ? { ...matchedFeature } : undefined;
            })
            ?.filter((i) => i)?.length > 0
            ? featuresList?.map((feature) => {
                const matchedFeature = formData?.features?.find(
                  (i) => i?.featureType === feature?._id
                );
                return matchedFeature ? { ...matchedFeature } : undefined;
              })
            : featuresList?.map((feature) => {
                const matchedFeature = contentOfferings?.[
                  index
                ]?.features?.find((i) => i?.featureType === feature?._id);

                return matchedFeature ? { ...matchedFeature } : undefined;
              }),
        featuresPreview:
          featuresList
            ?.map((feature) => {
              let name;
              const matchedFeature = formData?.features?.find((i) => {
                if (i?.featureType === feature?._id) {
                  name = feature?.name;
                  return true;
                }
                return false;
              });

              return matchedFeature ? { ...matchedFeature, name } : undefined;
            })
            ?.filter((i) => i)?.length > 0
            ? featuresList?.map((feature) => {
                let name;
                const matchedFeature = formData?.features?.find((i) => {
                  if (i?.featureType === feature?._id) {
                    name = feature?.name;
                    return true;
                  }
                  return false;
                });

                return matchedFeature ? { ...matchedFeature, name } : undefined;
              })
            : featuresList?.map((feature) => {
                let name;
                const matchedFeature = contentOfferings?.[
                  index
                ]?.features?.find((i) => {
                  if (i?.featureType === feature?._id) {
                    name = feature?.name;
                    return true;
                  }
                  return false;
                });

                return matchedFeature ? { ...matchedFeature, name } : undefined;
              }),
      };
    });
  }, [contentOfferings, featuresList, index]);
  return (
    <div className="bg-[#F8F8F8] border-[1px] border-[#C4C4C4] rounded-[8px] p-3">
      <Formik
        initialValues={
          {
            ...contentOfferings[index],
            typeId: formData?.typeId || formData?.typeId?._id || "",
            offeringName: formData?.offeringName,
            mediaKitprice: formData?.mediaKitprice,
            mediaDiscountedKitPrice: formData?.mediaDiscountedKitPrice,
            features:
              featuresList
                ?.map((feature) => {
                  const matchedFeature = formData?.features?.find(
                    (i) => i?.featureType === feature?._id
                  );

                  return matchedFeature ? { ...matchedFeature } : undefined;
                })
                ?.filter((i) => i)?.length > 0
                ? featuresList?.map((feature) => {
                    const matchedFeature = formData?.features?.find(
                      (i) => i?.featureType === feature?._id
                    );
                    return matchedFeature ? { ...matchedFeature } : undefined;
                  })
                : featuresList?.map((feature) => {
                    const matchedFeature = contentOfferings?.[
                      index
                    ]?.features?.find((i) => i?.featureType === feature?._id);

                    return matchedFeature ? { ...matchedFeature } : undefined;
                  }),
            featuresPreview:
              featuresList
                ?.map((feature) => {
                  let name;
                  const matchedFeature = formData?.features?.find((i) => {
                    if (i?.featureType === feature?._id) {
                      name = feature?.name;
                      return true;
                    }
                    return false;
                  });

                  return matchedFeature
                    ? { ...matchedFeature, name }
                    : undefined;
                })
                ?.filter((i) => i)?.length > 0
                ? featuresList?.map((feature) => {
                    let name;
                    const matchedFeature = formData?.features?.find((i) => {
                      if (i?.featureType === feature?._id) {
                        name = feature?.name;
                        return true;
                      }
                      return false;
                    });

                    return matchedFeature
                      ? { ...matchedFeature, name }
                      : undefined;
                  })
                : featuresList?.map((feature) => {
                    let name;
                    const matchedFeature = contentOfferings?.[
                      index
                    ]?.features?.find((i) => {
                      if (i?.featureType === feature?._id) {
                        name = feature?.name;
                        return true;
                      }
                      return false;
                    });

                    return matchedFeature
                      ? { ...matchedFeature, name }
                      : undefined;
                  }),
          } || {}
        }
        validationSchema={Yup.object().shape({
          typeId: Yup.string().required("Required"),
          mediaKitprice: Yup.number()
            .min(1, "Must be greater than zero")
            .required("Required"),
          mediaDiscountedKitPrice: Yup.number()
            .min(0)
            .max(
              Yup.ref("mediaKitprice"),
              "Discounted price should be less than or equal to media kit price"
            )
            .required("Required"),
        })}
        onSubmit={(
          {
            typeId,
            offeringName,
            mediaKitprice,
            mediaDiscountedKitPrice,
            features,
            price,
            featuresPreview,
          },
          { setSubmitting, resetForm }
        ) => {
          let validate = true;
          offeringData?.contentOfferings?.forEach((offer, idx) => {
            const oldOffer = offer?.features?.filter((i) => i);
            if (offer.typeId === typeId && idx !== index) {
              if (offer?.features?.length === features?.length) {
                const featuresSet1 = new Set(
                  oldOffer?.map((i) => i?.featureType)
                );
                const featuresSet2 = new Set(
                  features?.map((i) => i?.featureType)
                );

                const featuresMatch = [...featuresSet1].every((feature) =>
                  featuresSet2.has(feature)
                );

                if (featuresMatch) {
                  validate = false;
                }
              } else if (
                offer?.features?.length === 0 &&
                features?.length === 0
              ) {
                validate = false;
              }
            }
          });

          if (validate === false) {
            toast.error("Content Offering already exists");
            return;
          }

          contentOfferings[index] = {
            ...(contentOfferings[index] || []),
            typeId,
            offeringName,
            price,
            mediaKitprice,
            mediaDiscountedKitPrice,
            features: features?.filter((i) => i?.checked),
            featuresPreview: featuresPreview?.filter((i) => i?.checked),
          };
          dispatch(
            addOfferingDataForm({
              contentOfferings: [...contentOfferings],
            })
          );
          setSubmitting(false);
          setFeaturesList(null);
          resetForm();
          handleDataEditClose();
        }}
        enableReinitialize
      >
        {({ values, errors, handleChange, setFieldValue, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit} className="flex flex-col gap-4">
              <div className="rounded-lg flex flex-col gap-2">
                <label
                  htmlFor={`typeId-${index}`}
                  className="font-[510] text-[14px] leading-[21px] text-[#474747]"
                >
                  Select Offering Type
                </label>
                <select
                  name="typeId"
                  className="w-[100%] py-[12px] px-[13px] rounded-[8px] border-1 border-[#DBDBDB] Outline"
                  id={`typeId-${index}`}
                  value={values.typeId?._id || values.typeId || ""}
                  onChange={(e) => {
                    handleChange(e);
                    handleFetchFeatures(e.target.value, index);
                    setFormData((prev) => {
                      return {
                        ...(prev || {}),
                        offeringName:
                          e.target.selectedOptions[0].getAttribute("data-id"),
                        typeId: e.target.value,
                      };
                    });
                    setFieldValue(
                      "offeringName",
                      e.target.selectedOptions[0].getAttribute("data-id")
                    );
                  }}
                >
                  <option value="">Choose offering</option>
                  {offeringList?.map((item, index) => (
                    <option key={index} value={item._id} data-id={item.name}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {errors.typeId && (
                  <span className="text-sm text-red-500">{errors.typeId}</span>
                )}
              </div>
              <div className="row gx-2 gy-4">
                <div className="col-lg-6">
                  <div className="rounded-lg">
                    <label
                      htmlFor={`mediaKitprice-${index}`}
                      className="font-[510] text-[14px] leading-[21px] text-[#474747]"
                    >
                      Media Kit Price
                    </label>
                    <input
                      type="number"
                      id={`mediaKitprice-${index}`}
                      onChange={(e) => {
                        handleChange(e);
                        setFormData((prev) => {
                          return {
                            ...(prev || {}),
                            mediaKitprice: e.target.value,
                          };
                        });
                      }}
                      value={values?.mediaKitprice}
                      name="mediaKitprice"
                      placeholder="00"
                      className="w-[100%] py-[12px] px-[13px] rounded-[8px] mt-2 font-[510] text-[14px] leading-[21px] border-1 border-[#DBDBDB] Outline"
                    />
                    {errors.mediaKitprice && (
                      <span className="text-sm text-red-500">
                        {errors.mediaKitprice}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="rounded-lg">
                    <label
                      htmlFor={`mediaDiscountedKitPrice-${index}`}
                      className="font-[510] text-[14px] leading-[21px] text-[#474747]"
                    >
                      Discounted Price
                    </label>
                    <input
                      type="number"
                      placeholder="00"
                      id={`mediaDiscountedKitPrice-${index}`}
                      onChange={(e) => {
                        handleChange(e);
                        setFormData((prev) => {
                          return {
                            ...(prev || {}),
                            mediaDiscountedKitPrice: e.target.value,
                          };
                        });
                      }}
                      value={values.mediaDiscountedKitPrice}
                      name="mediaDiscountedKitPrice"
                      className="w-[100%] py-[12px] px-[13px] rounded-[8px] mt-2 font-[510] text-[14px] leading-[21px] border-1 border-[#DBDBDB] Outline"
                    />
                    {errors.mediaDiscountedKitPrice && (
                      <span className="text-sm text-red-500">
                        {errors.mediaDiscountedKitPrice}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="rounded-lg">
                    <p className="font-[510] text-[14px] leading-[21px] text-[#474747]">
                      Features
                    </p>
                    <div className="">
                      {Array.isArray(featuresList) &&
                        featuresList?.map((item, index) => {
                          return (
                            <div
                              className="mt-1 flex flex-wrap gap-3 items-center"
                              key={item._id}
                            >
                              <input
                                type="checkbox"
                                id={item._id + index}
                                onChange={(e) => {
                                  const checked = e.target.checked;
                                  console.log(checked);

                                  if (checked) {
                                    setFieldValue(
                                      "features",
                                      featuresList?.map((_, i) => {
                                        if (i === index)
                                          return {
                                            featureType: item?._id,
                                            checked: true,
                                            value:
                                              featuresList?.[index]?.type ===
                                              "Text"
                                                ? values?.features?.[i]?.value
                                                : "yes",
                                          };
                                        return values?.features?.[i];
                                      })
                                    );
                                    setFieldValue(
                                      "featuresPreview",
                                      featuresList?.map((_, i) => {
                                        if (i === index)
                                          return {
                                            featureType: item._id,
                                            name: item.name,
                                            id: item._id,
                                            checked: true,
                                            value:
                                              featuresList?.[index]?.type ===
                                              "Text"
                                                ? values?.features?.[i]?.value
                                                : "yes",
                                          };
                                        return values?.featuresPreview?.[i];
                                      })
                                    );
                                    setFormData((prev) => {
                                      return {
                                        ...(prev || {}),
                                        features: featuresList?.map((_, i) => {
                                          if (i === index)
                                            return {
                                              featureType: item?._id,
                                              checked: true,
                                              value:
                                                featuresList?.[index]?.type ===
                                                "Text"
                                                  ? values?.features?.[i]?.value
                                                  : "yes",
                                            };
                                          return formData?.features?.[i];
                                        }),
                                        featuresPreview: featuresList?.map(
                                          (_, i) => {
                                            if (i === index)
                                              return {
                                                featureType: item._id,
                                                name: item.name,
                                                id: item._id,
                                                checked: true,
                                                value:
                                                  featuresList?.[index]
                                                    ?.type === "Text"
                                                    ? values?.features?.[i]
                                                        ?.value
                                                    : "yes",
                                              };
                                            return formData?.featuresPreview?.[
                                              i
                                            ];
                                          }
                                        ),
                                      };
                                    });
                                  } else {
                                    setFieldValue(
                                      "features",
                                      featuresList?.map((_, i) => {
                                        if (i === index)
                                          return {
                                            featureType: item._id,
                                            checked: false,
                                            value:
                                              featuresList?.[index]?.type ===
                                              "Text"
                                                ? values?.features?.[i]?.value
                                                : "no",
                                          };
                                        return values.features[i];
                                      })
                                    );

                                    setFieldValue(
                                      "featuresPreview",
                                      featuresList?.map((_, i) => {
                                        if (i === index)
                                          return {
                                            featureType: item._id,
                                            checked: false,
                                            name: item.name,
                                            id: item._id,
                                            value:
                                              featuresList?.[index]?.type ===
                                              "Text"
                                                ? values?.featuresPreview?.[i]
                                                    ?.value
                                                : "no",
                                          };
                                        return values?.featuresPreview?.[i];
                                      })
                                    );

                                    setFormData((prev) => {
                                      return {
                                        ...(prev || {}),
                                        features: featuresList?.map((_, i) => {
                                          if (i === index)
                                            return {
                                              featureType: item._id,
                                              checked: false,
                                              value:
                                                featuresList?.[index]?.type ===
                                                "Text"
                                                  ? values?.features?.[i]?.value
                                                  : "no",
                                            };
                                          return formData?.features?.[i];
                                        }),
                                        featuresPreview: featuresList?.map(
                                          (_, i) => {
                                            if (i === index)
                                              return {
                                                featureType: item._id,
                                                checked: false,
                                                value:
                                                  featuresList?.[index]
                                                    ?.type === "Text"
                                                    ? values?.features?.[i]
                                                        ?.value
                                                    : "no",
                                              };
                                            return formData?.features[i];
                                          }
                                        ),
                                      };
                                    });
                                  }
                                }}
                                checked={formData?.features?.[index]?.checked}
                              />
                              <label
                                htmlFor={item._id + index}
                                className="font-[510] text-[14px] leading-[21px] text-[#474747] "
                              >
                                {item.name}
                              </label>
                              {item?.type === "Text" && (
                                <input
                                  type="text"
                                  id={item._id + index}
                                  className="px-[8px] py-[2px]  font-[510] text-[14px] leading-[21px] border-1 border-[#DBDBDB] Outline"
                                  onChange={(e) => {
                                    setFieldValue(
                                      "features",
                                      featuresList?.map((_, i) => {
                                        if (i === index)
                                          return {
                                            ...values?.features?.[i],
                                            value: e.target.value,
                                          };
                                        return values.features?.[i];
                                      })
                                    );

                                    setFieldValue(
                                      "featuresPreview",
                                      featuresList?.map((_, i) => {
                                        if (i === index)
                                          return {
                                            ...values?.featuresPreview?.[i],
                                            value: e.target.value,
                                          };
                                        return values.featuresPreview?.[i];
                                      })
                                    );

                                    setFormData((prev) => {
                                      return {
                                        ...(prev || {}),
                                        features: featuresList?.map((_, i) => {
                                          if (i === index)
                                            return {
                                              ...formData?.features?.[i],
                                              value: e.target.value,
                                            };
                                          return formData?.features?.[i];
                                        }),
                                        featuresPreview: featuresList?.map(
                                          (_, i) => {
                                            if (i === index)
                                              return {
                                                ...formData?.featuresPreview?.[
                                                  i
                                                ],
                                                value: e.target.value,
                                              };
                                            return formData?.featuresPreview?.[
                                              i
                                            ];
                                          }
                                        ),
                                      };
                                    });
                                  }}
                                  value={formData?.features?.[index]?.value}
                                />
                              )}
                              <br />
                            </div>
                          );
                        })}
                      <div>
                        {values.typeId && (
                          <>
                            {!addFeature && (
                              <div
                                className="flex mt-3 items-center gap-2 font-bold cursor-pointer"
                                onClick={() => setAddFeature(true)}
                              >
                                <LuPlus className="" size={18} />
                                Add New Feature
                              </div>
                            )}
                            {addFeature && (
                              <div className="flex gap-2 mt-3">
                                <input
                                  type="text"
                                  placeholder="Feature"
                                  className="outline-none px-2"
                                  onChange={(e) => {
                                    setAddFeatureData((prev) => {
                                      return {
                                        ...(prev || {}),
                                        name: e.target.value,
                                      };
                                    });
                                  }}
                                />
                                <input
                                  type="text"
                                  placeholder="Value"
                                  className="outline-none px-2"
                                  onChange={(e) => {
                                    setAddFeatureData((prev) => {
                                      return {
                                        ...(prev || {}),
                                        value: e.target.value,
                                      };
                                    });
                                  }}
                                />
                                <button
                                  type="button"
                                  className="cursor-pointer text-[#FFFFFF] px-2 py-1 transition-transform transform active:bg-gray-800   active:scale-95 active:shadow-lg rounded-[5px] bg-[#0A0A0A]"
                                  onClick={() => {
                                    if (
                                      (values.typeId._id || values.typeId) &&
                                      !AddFeatureData.name
                                    ) {
                                      return;
                                    }
                                    addNewFeature(
                                      {
                                        ...AddFeatureData,
                                        accessibility: "private",
                                        type: "Text",
                                        offeringTypeList: [
                                          values.typeId?._id || values.typeId,
                                        ],
                                      },
                                      setFieldValue,
                                      values
                                    );
                                  }}
                                >
                                  Add
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-wrap gap-x-4 gap-y-2">
                  <button
                    className="text-[#3772FF] font-semibold"
                    onClick={() => handleOpenModel(values.typeId)}
                    type="button"
                  >
                    {" "}
                    {values.example && Object?.keys(values.example)?.length > 0
                      ? "Edit"
                      : "+ Add"}{" "}
                    Example
                  </button>
                  <div className="flex items-center gap-2">
                    <IoMdInformationCircleOutline className="text-[#000000]" />
                    <span className="text-[#474747] text-[13px]">
                      why this is important?
                    </span>
                  </div>
                </div>
              </div>
              {/* )} */}
              <div className="flex items-center gap-3">
                <button
                  type="submit"
                  className="text-[#FFFFFF] px-8 py-2 transition-transform transform active:bg-gray-800   active:scale-95 active:shadow-lg rounded-[5px] bg-[#0A0A0A]"
                >
                  Done
                </button>
                <button
                  className="text-3xl ml-auto "
                  onClick={() => {
                    handleDeleteOffering(index);
                  }}
                >
                  <MdDelete />
                </button>
              </div>
            </form>
          );
        }}
      </Formik>

      <Modal show={open} onHide={handleCloseModel} size="md" centered>
        <Modal.Body>
          <h1 className="font-[590] text-[20px] leading-[24px] text-[#000000]">
            Upload Example
          </h1>
          <Formik
            initialValues={
              contentOfferings[index]?.example || {
                links: [
                  {
                    title: "",
                    value: "",
                  },
                ],
                screenshots: [],
              }
            }
            validationSchema={Yup.object().shape({
              links: Yup.array().of(
                Yup.object().shape({
                  title: Yup.string().optional(),
                  value: Yup.string()
                    .optional()
                    .matches(/^http/, "Value must start with 'http'"),
                })
              ),
              screenshotsPreview: Yup.mixed().optional(),
            })}
            onSubmit={(values, { setSubmitting, resetForm: reset }) => {
              const otherValues = {};
              let screenshotsValue = {};

              for (const key in values) {
                if (key?.includes("screenshots")) {
                  screenshotsValue[key] = values[key];
                } else {
                  otherValues[key] = values[key];
                }
              }

              contentOfferings[index].example = otherValues;

              dispatch(
                addOfferingDataForm({
                  contentOfferings: [...contentOfferings],
                  ...screenshotsValue,
                })
              );
              setSubmitting(false);
              // reset();
              handleCloseModel();
            }}
            enableReinitialize
          >
            {({ values, errors, handleSubmit, setFieldValue }) => (
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                  <div className="row gx-2">
                    <div
                      className={`flex flex-col gap-2 col-${
                        values.links.length > 1 ? "4" : "5"
                      }`}
                    >
                      <label>Link Title</label>
                    </div>
                    <div className="flex flex-col gap-2 col-7">
                      <label>Link</label>
                    </div>
                  </div>
                  {values.links?.map((item, index) => (
                    <div className="row gx-2" key={index}>
                      <div
                        className={`flex flex-col gap-2 col-${
                          values.links.length > 1 ? "4" : "5"
                        }`}
                      >
                        <input
                          type="text"
                          name="title"
                          value={item.title}
                          onChange={(e) => {
                            values.links[index].title = e.target.value;
                            setFieldValue("links", values.links);
                          }}
                          className="w-[100%] py-[12px] px-[13px] rounded-[8px] font-[510] text-[14px] leading-[21px] border-1 border-[#DBDBDB] Outline"
                          placeholder="Enter Link Title"
                        />
                        {errors.links ? (
                          errors.links[index] ? (
                            errors.links[index].title ? (
                              <span className="text-sm text-red-500">
                                {errors.links[index].title}
                              </span>
                            ) : null
                          ) : null
                        ) : null}
                      </div>
                      <div className="flex flex-col gap-2 col-7">
                        <input
                          type="text"
                          name="value"
                          value={item.value}
                          onChange={(e) => {
                            values.links[index].value = e.target.value;
                            setFieldValue("links", values.links);
                          }}
                          className="w-[100%] py-[12px] px-[13px] rounded-[8px] font-[510] text-[14px] leading-[21px] border-1 border-[#DBDBDB] Outline"
                          placeholder="Enter Link"
                        />
                        {errors.links ? (
                          errors.links[index] ? (
                            errors.links[index].value ? (
                              <span className="text-sm text-red-500">
                                {errors.links[index].value}
                              </span>
                            ) : null
                          ) : null
                        ) : null}
                      </div>
                      {values.links.length > 1 && (
                        <div className="flex col-1 items-center justify-center">
                          <button
                            type="button"
                            className="text-[22px]"
                            onClick={() => {
                              const newLinks = [...values.links];
                              newLinks.splice(index, 1);
                              setFieldValue("links", newLinks);
                            }}
                          >
                            <TiDelete />
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="bg-[#0A0A0A] text-[14px] leading-[21px] text-[#fff] py-2 px-2 rounded-[8px]"
                    onClick={() => {
                      setFieldValue("links", [
                        ...values.links,
                        {
                          title: "",
                          value: "",
                        },
                      ]);
                    }}
                  >
                    + Add new link
                  </button>
                </div>

                <div className="flex flex-col gap-2">
                  <h3 className="text-[18px] font-bold mb-0">
                    Upload Screen Shot
                  </h3>
                  <span>For multiple images you can upload PDF</span>

                  <div className="border-[2px] border-dashed border-[#DBDBDB] p-4 rounded-lg">
                    <input
                      ref={inputRef}
                      type="file"
                      multiple
                      style={{ display: "none" }}
                      onChange={(e) => {
                        const files = Array.from(e.currentTarget.files); // Convert FileList to array

                        setFieldValue(`${open}_screenshots`, files);

                        const screenshotsPreview = files.map((file) =>
                          Object.assign(file, {
                            preview: URL.createObjectURL(file),
                          })
                        );

                        setFieldValue("screenshotsPreview", screenshotsPreview);
                      }}
                    />
                    <div onClick={onButtonClick} className="font-[300]">
                      <img
                        src="/assets/images/account/upload.png"
                        className="mx-auto mb-3 w-[90px]"
                        alt="upload"
                      />
                      <p className="md:text-base text-[13px] font-[590] mb-1">
                        Browse Files to upload
                      </p>
                      <div className="text-[12px]">
                        Max file size 5 MB. Supported file types: png, jpeg,
                        pdf, doc.
                      </div>
                    </div>
                  </div>
                  {errors.screenshotsPreview && (
                    <span className="text-sm text-red-500">
                      {errors.screenshotsPreview}
                    </span>
                  )}
                  {Array.isArray(values.screenshotsPreview) &&
                    values.screenshotsPreview?.map((file) => (
                      <div
                        key={file.name}
                        className="file-item d-flex items-center justify-between bg-[#F1F8FF] p-3 rounded-lg"
                      >
                        <p className="file-name mb-0 d-flex items-center gap-3">
                          <FaRegFileAlt />
                          {file.preview.name ?? file.name}
                        </p>
                        <button
                          onClick={() => {
                            setFieldValue(
                              "screenshots",
                              Object.values(values.screenshots).filter(
                                (f) => f !== file
                              )
                            );
                            setFieldValue(
                              "screenshotsPreview",
                              Object.values(values.screenshotsPreview).filter(
                                (f) => f !== file
                              )
                            );
                          }}
                          type="button"
                          className="remove-button text-[#FF543E] text-xl"
                        >
                          <RiDeleteBin6Line />
                        </button>
                      </div>
                    ))}
                </div>
                <p className="flex gap-2 mb-0">
                  <IoMdInformationCircleOutline className="text-[24px]" />
                  <span>
                    This example helps in getting more business to you; it shows
                    users how their PR looks like on your platform.
                  </span>
                </p>
                <div className="flex items-center gap-3">
                  <button
                    type="submit"
                    className="bg-[#0A0A0A] rounded px-4 py-2 text-[#FFFFFF] transition-transform transform active:bg-gray-800   active:scale-95 active:shadow-lg"
                  >
                    Upload
                  </button>
                  <button
                    type="button"
                    className="border-[1px] border-[#0A0A0A] rounded px-4 py-2"
                    onClick={handleCloseModel}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ContentOffering;
