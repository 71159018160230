import React, { useEffect, useState } from "react";
// import { BiEditAlt } from "react-icons/bi";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { authApi } from "../../api";

const LivePackage = () => {
  // const [offeringCategoryLs, setOfferingCategoryLs] = useState([]);
  // const [selectedCategory, setSelectedCategory] = useState([]);
  const [offeringsTypeList, setOfferingsTypeList] = useState([]);
  const [packageType, setPackageType] = useState("basic");
  const [data, setData] = useState([]);

  // useEffect(() => {
  //   const fetchOfferingCategoryLs = async () => {
  //     try {
  //       const response = authApi.get(`/offering-categories`);
  //       setOfferingCategoryLs((await response).data.offeringCategoryList);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   fetchOfferingCategoryLs();
  // }, []);

  useEffect(() => {
    handleContentOffering();
  }, []);

  const handleContentOffering = async () => {
    const {
      data: { offeringList },
    } = await authApi.get(`/offerings`);

    const contentOfferings = offeringList.reduce((acc, offering) => {
      if (Array.isArray(offering.contentOfferings)) {
        return acc.concat(offering.contentOfferings);
      }
      return acc;
    }, []);

    const uniqueContentOfferings = contentOfferings.filter(
      (offering, index, self) =>
        offering?.typeId?.name &&
        index ===
          self.findIndex((o) => o?.typeId?.name === offering?.typeId?.name)
    );
    setOfferingsTypeList(uniqueContentOfferings);
  };

  const fetchPackagesData = async () => {
    try {
      const response = await authApi.get(`/packages/my`, {
        // params: {
        //   status: "created",
        // },
      });
      setData(response.data.packageList);
      console.log(response.data.packageList, "Fetched packages data");
    } catch (error) {
      console.error("Error fetching packages data:", error);
    }
  };

  useEffect(() => {
    fetchPackagesData();
  }, []);

  console.log({ data });

  return (
    <div className="bg-white border-1 border-[#DBDBDB] rounded-lg py-3 px-4 h-full package">
      <div className="flex items-center justify-between mt-[16px] pb-[16px]">
        <h3 className="text-[18px] font-[590] mb-0">Your Live Packages</h3>
        {/* <span className="flex items-center blue-color">
          {" "}
          <BiEditAlt className="text-xl" /> Edit
        </span> */}
      </div>

      {/* <div className="dropdown rounded-lg bg-white mb-3">
        <div className="w-[100%]">
          <label
            htmlFor="offeringCategoryId"
            className="font-[510] text-[14px] leading-[21px] text-[#474747]"
          >
            Select Category
          </label>{" "}
          <br />
          <select
            name="offeringCategoryId"
            className="w-[100%] py-[12px] px-[13px] rounded-[8px] mt-2 border-1 border-[#DBDBDB] Outline"
            id="offeringCategoryId"
            onChange={(e) => {
              setSelectedCategory(e.target.value);
            }}
            value={selectedCategory}
          >
            <option
              className="font-[400] text-[14px] leading-[21px] text-[#474747]"
              disabled
              value=""
            >
              Choose Category
            </option>
            {offeringCategoryLs?.map((item, index) => (
              <option
                key={item?._id || index}
                value={item._id}
                data-id={item.name}
              >
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div> */}
      <div className="pt-1">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="Basic-tab"
              data-bs-toggle="pill"
              data-bs-target="#Basic"
              type="button"
              role="tab"
              aria-controls="Basic"
              aria-selected="true"
              onClick={() => setPackageType("basic")}
            >
              Basic
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="Advance-tab"
              data-bs-toggle="pill"
              data-bs-target="#Advance"
              type="button"
              role="tab"
              aria-controls="Advance"
              aria-selected="false"
              onClick={() => setPackageType("silver")}
            >
              Silver
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="Premium-tab"
              data-bs-toggle="pill"
              data-bs-target="#Premium"
              type="button"
              role="tab"
              aria-controls="Premium"
              onClick={() => setPackageType("advance")}
              aria-selected="false"
            >
              Advance
            </button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          {data.map((pkg, index) => {
            if (
              packageType === pkg.name
              //  &&
              // (selectedCategory === pkg?.offeringCategoryId?._id ||
              //   selectedCategory === pkg?.offeringCategoryId)
            )
              return (
                <div
                  key={pkg._id}
                  className={`tab-pane fade mb-2 ${
                    index.toString() ? "show active" : ""
                  }`}
                  id={pkg.name}
                  role="tabpanel"
                  aria-labelledby={`${pkg.name}-tab`}
                  tabIndex="0"
                >
                  <div
                    class="tab-pane fade show active"
                    id="Basic"
                    role="tabpanel"
                    aria-labelledby="Basic-tab"
                    tabindex="0"
                  >
                    <div className="borders rounded-lg">
                      <h3 className="text-[20px] font-[590] border-b border-[#DBDBDB] p-2 capitalize">
                        {pkg.name}
                      </h3>
                      <div className="p-2">
                        <p className="text-[#474747] font-[590]">Offerings</p>
                        <ul>
                          {pkg.offerings.map((offering) => (
                            <li
                              key={offering.id._id}
                              className="bg-[#F9F9F9] borders md:text-base text-[13px] flex items-center p-2"
                            >
                              <IoIosCheckmarkCircle className="me-2" />
                              {offering.qty} {"  "}
                              {offering?.name ??
                                offeringsTypeList?.find(
                                  (i) =>
                                    i?._id?.toString() ===
                                    offering?.id?.toString()
                                )?.typeId?.name}
                            </li>
                          ))}
                        </ul>

                        <div>
                          <label className="text-sm font-[510] my-3">
                            Other offerings
                          </label>
                          <textarea
                            placeholder="Type description here...."
                            value={pkg.additionalDetails}
                            disabled
                            className="h-[113px] w-full border-1 bg-[#F5F5F5BA] border-[#DBDBDB] rounded-md p-[10px] overflow-y-scroll resize-none"
                          ></textarea>
                        </div>
                        <div className="flex items-center gap-1 mb-3">
                          <div className="flex items-center bg-[#EDEDED] md:px-[20px] md:py-[10px] px-[16px] py-[8px] text-[12px] rounded-md">
                            <img
                              src="/assets/images/account/payments.png"
                              className="me-2"
                              alt=""
                            />
                            Total: ${pkg.totalAmount}
                          </div>
                          <div className="flex items-center bg-[#EDEDED] md:px-[20px] md:py-[10px] px-[16px] py-[8px] text-[12px] rounded-md">
                            <img
                              src="/assets/images/account/payments.png"
                              className="me-2"
                              alt=""
                            />
                            Discounted: ${pkg.discountedAmount}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
          })}
        </div>
      </div>
    </div>
  );
};

export default LivePackage;
