import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const TrustedBy = () => {
    return (
        <div className='container-fluid trust mt-[80px]'>
            <div>
                <h1 className='font-[590] text-center text-[24px] leading-[28.8px] text-[#000000]'>Trusted By</h1>
                <OwlCarousel
                    className='owl-theme !mt-[32px]'
                    loop
                    margin={10}
                    items={4}
                    autoplay
                    autoplayTimeout={2000}
                    slideTransition='linear'
                    nav={false}
                    dots={false}
                    smartSpeed={2000}
                    stagePadding={75}
                    responsive={{
                        0: {
                            items: 2,
                            stagePadding: 20
                        },
                        600: {
                            items: 3,
                            stagePadding: 30
                        },
                        700:{
                            items:4,
                            stagePadding:50
                        },
                        800:{
                            items:5,
                            stagePadding:60
                        },
                        1000: {
                            items: 6,
                            stagePadding: 75
                        }
                    }}
                >
                    <div className='item !flex !justify-center'>
                        <img src='/assets/images/Package/trusted-1.png' className='!w-[285px]' />
                    </div>
                    <div className='item !flex !justify-center'>
                        <img src='/assets/images/Package/trusted-2.png' className='!w-[285px]' />
                    </div>
                    <div className='item !flex !justify-center'>
                        <img src='/assets/images/Package/trusted-3.png' className='!w-[285px]' />
                    </div>
                    <div className='item !flex !justify-center'>
                        <img src='/assets/images/Package/trusted-4.png' className='!w-[285px]' />
                    </div>
                    <div className='item !flex !justify-center'>
                        <img src='/assets/images/Package/trusted-1.png' className='!w-[285px]' />
                    </div>
                    <div className='item !flex !justify-center'>
                        <img src='/assets/images/Package/trusted-2.png' className='!w-[285px]' />
                    </div>
                    <div className='item !flex !justify-center'>
                        <img src='/assets/images/Package/trusted-3.png' className='!w-[285px]' />
                    </div>
                    <div className='item !flex !justify-center'>
                        <img src='/assets/images/Package/trusted-4.png' className='!w-[285px]' />
                    </div>
                </OwlCarousel>
            </div>
        </div>
    )
}

export default TrustedBy;
