import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { ASSETBASEURL } from "../../baseUrl";
import { useCallback } from "react";

const SpecificPackage = ({ data, addToCart, setData }) => {
  const calculateAddOnPrice = useCallback(
    (index) => {
      let addOnPrice = 0;
      if (data?.[index]?.addOns) {
        data[index].addOns.forEach((addOn) => {
          if (addOn.selectedAddOn) {
            addOnPrice += parseFloat(addOn.price);
          }
        });
      }
      return addOnPrice;
    },
    [data]
  );
  return (
    <>
      <div className="row buttons">
        {Array.isArray(data) &&
          data?.length > 0 &&
          data.map((item, mainIndex) => (
            <div
              key={item?._id}
              className="col-xl-3 col-md-6 md:m-xl-auto !mt-[10px]"
            >
              {console.log({ item })}
              <div class="h-full bg-white rounded-lg border-2 border-gray-300 flex flex-col justify-between relative overflow-hidden Add-Grediant z-10">
                {item?.name === "Premium" && (
                  <>
                    <img
                      src="/assets/images/Package/Second-gridiant.png"
                      className="gridiant-Image"
                      alt=""
                    />
                    <img
                      src="/assets/images/Package/Grediant.png"
                      className="gridiant-Image z-10 !-right-10"
                      alt=""
                    />
                  </>
                )}
                <div className="p-6 z-10">
                  <h2 class="md:text-xl text-base mb-3 font-[590]">
                    {item?.displayName || item?.name}
                  </h2>
                  <h1 class="md:text-[38px] text-[30px] mb-0 font-[510]">
                    ${item?.discountedPrice + calculateAddOnPrice(mainIndex)}
                  </h1>
                  <span className="text-[#128735] font-[590] mb-3 block line-through">
                    ${item?.price + calculateAddOnPrice(mainIndex)}
                  </span>
                  <div
                    onClick={() => addToCart(item?._id, "whole")}
                    className="cursor-pointer bg-black-color block text-white no-underline font-[590] text-center px-[20px] py-[15px] rounded-xl"
                  >
                    Add to Cart
                  </div>
                  <div className="text-black text-center block py-3 fonr-[510] border-b mb-3">
                    {/* Contact Sales */}
                  </div>

                  <h3 className="font-[590] md:text-[24px] text-[20px] mb-3">
                    Includes
                  </h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item?.packageDescription,
                    }}
                  />
                  {Array.isArray(item?.guaranteedPublisher) &&
                    item?.guaranteedPublisher?.length > 0 && (
                      <>
                        <h3 className="font-[590] md:text-[24px] text-[20px] my-3">
                          Guaranteed publishers
                        </h3>
                        <div className="row gx-2 gy-2 mb-4">
                          {item?.guaranteedPublisher?.map(
                            (publisher, index) => (
                              <div className="col-6" key={index}>
                                <img
                                  className="w-full h-[50%] "
                                  src={ASSETBASEURL + "/" + publisher}
                                  alt="logo3"
                                />
                              </div>
                            )
                          )}
                        </div>
                      </>
                    )}
                  {Array.isArray(item?.addOns) && item?.addOns?.length > 0 && (
                    <>
                      <h3 className="font-[590] md:text-[24px] text-[20px] mb-3">
                        Add-ons
                      </h3>

                      <div className="row">
                        <div className="col-12">
                          {item?.addOns?.map((addOn, index) => {
                            const addonData =
                              addOn?.offeringType?.contentOfferings?.find(
                                (i) => i?._id === addOn.offeringTypeId
                              );
                            const price =
                              addonData?.livePrice > 0
                                ? addonData?.livePrice
                                : addonData?.mediaKitprice;
                            return (
                              <div className="border rounded-lg p-2 mb-3 d-flex items-center justify-between">
                                <img
                                  src="/assets/images/Package/logo4.png"
                                  alt="logo4"
                                />
                                <div>
                                  <p className="font-[590] mb-1">${price}</p>
                                  <div className="d-flex items-center gap-2">
                                    <span className="text-[10px] font-[590]">
                                      Add
                                    </span>
                                    <Form>
                                      <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        checked={!!addOn?.selectedAddOn}
                                        onChange={(e) => {
                                          setData((prev) =>
                                            prev?.map((item) => {
                                              return {
                                                ...item,
                                                addOns: item.addOns?.map(
                                                  (addOn, idx) => {
                                                    if (idx === index) {
                                                      return {
                                                        ...addOn,
                                                        price: e.target.checked
                                                          ? addOn?.price + price
                                                          : addOn?.price -
                                                            price,
                                                        selectedAddOn:
                                                          e.target.checked,
                                                      };
                                                    }
                                                    return addOn;
                                                  }
                                                ),
                                              };
                                            })
                                          );
                                        }}
                                      />
                                    </Form>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="bg-black p-3 d-flex items-center gap-3">
                  <div className="text-white">
                    <span className="text-[13px]">Final Price</span>
                    <div className="text-[24px] font-[590]">
                      ${item?.discountedPrice + calculateAddOnPrice(mainIndex)}
                    </div>
                  </div>
                  <div className="text-center w-full p-2 bg-white rounded cursor-pointer">
                    <p
                      className="font-[590] mb-0"
                      onClick={() => {
                        addToCart(item?._id, mainIndex);
                      }}
                    >
                      Add to cart
                    </p>
                    <span className="text-[10px] block">
                      (
                      {calculateAddOnPrice(mainIndex) > 0 ? `With` : `With Out`}{" "}
                      Ad-ons)
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </>
  );
};

export default SpecificPackage;
