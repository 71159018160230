import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserData, updateFormData } from "../../redux/actions/auth.action";
import { authApi } from "../../api";

const IdentificationDetails = ({ userData,initialData, handleNext }) => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState(initialData);
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.authentication);


 

  useEffect(() => {
    if (userData) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        
        ...userData
      }));
    }
  }, [userData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleRadioChange = (e) => {
    console.log({ e: e.target.value });

    setFormData({ ...formData, isCompany: e.target.value === "yes" });
  };
  const validate = () => {
    const newErrors = {};
    if (!formData.fullname) newErrors.fullname = "Full name is required";
    if (!formData.email) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    if (!formData?.country)
      newErrors.country = "Country of residence is required";
    if (!formData?.address) newErrors.address = "Address is required";
    if (formData?.isCompany) {
      if (!formData?.companyName)
        newErrors.companyName = "Company name is required";
      if (!formData?.companyEmail) {
        newErrors.companyEmail = "Company email is required";
      } else if (!/\S+@\S+\.\S+/.test(formData.companyEmail)) {
        newErrors.companyEmail = "Company email is invalid";
      }
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    dispatch(updateFormData(formData)); // Dispatch action to update Redux state
    handleNext(); // Proceed to the next step
  };

  return (
    <div className="identification_details bg-white border-1 border-[#DBDBDB] rounded-lg py-3 md:px-[30px] px-[16px]">
      <h3 className="md:text-[20px] text-base font-[590]">
        Identification Details
      </h3>
      <p className="mb-5 md:text-base text-[13px]">
        Your details will be used for billing and generating invoice
      </p>

      <form onSubmit={handleSubmit}>
        <div className="mb-5">
          <h3 className="md:text-[20px] text-base font-[590] mb-4">
            I represent a company
          </h3>
          <div className="flex gap-5">
            <div className="form-check">
              <input
                className="form-check-input me-3 mt-0 !w-[21px] !h-[21px]"
                type="radio"
                name="isCompany"
                value="yes"
                checked={formData?.isCompany === true}
                onChange={handleRadioChange}
              />
              <label
                className="form-check-label font-[500] md:text-base text-[13px]"
                htmlFor="isCompanyYes"
              >
                Yes
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input me-3 mt-0 !w-[21px] !h-[21px]"
                type="radio"
                name="isCompany"
                value="no"
                checked={formData?.isCompany === false}
                onChange={handleRadioChange}
              />
              <label
                className="form-check-label font-[500] md:text-base text-[13px]"
                htmlFor="isCompanyNo"
              >
                No
              </label>
            </div>
          </div>
        </div>

        <div className="sign-up">
          <h3 className="md:text-[20px] text-base font-[590] mb-4">
            Enter your details
          </h3>
          <div className="row">
            <div className="col-lg-8">
              <div className="row gy-4">
                <div className="col-sm-6">
                  <input
                    type="text"
                    name="fullname"
                    className="sign-input"
                    placeholder="Full Name"
                    value={formData?.fullname}
                    onChange={handleChange}
                  />
                  {errors.fullname && (
                    <p className="text-danger">{errors.fullname}</p>
                  )}
                </div>
                <div className="col-sm-6">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email ID"
                    className="sign-input"
                    value={formData?.email}
                    onChange={handleChange}
                  />
                  {errors.email && (
                    <p className="text-danger">{errors.email}</p>
                  )}
                </div>
                <div className="col-sm-6">
                  <input
                    type="text"
                    name="country"
                    placeholder="Country of Residence"
                    className="sign-input"
                    value={formData?.country}
                    onChange={handleChange}
                  />
                  {errors.country && (
                    <p className="text-danger">{errors.country}</p>
                  )}
                </div>
                <div className="col-sm-6">
                  <input
                    type="text"
                    name="address"
                    placeholder="Address"
                    className="sign-input"
                    value={formData?.address}
                    onChange={handleChange}
                  />
                  {errors.address && (
                    <p className="text-danger">{errors.address}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          {formData?.isCompany && (
            <div className="row mt-4 ">
              <div className="col-sm-6">
                <input
                  type="text"
                  name="companyName"
                  placeholder="Company Name"
                  className="sign-input"
                  value={formData?.companyName}
                  onChange={handleChange}
                />
                {errors.companyName && (
                  <p className="text-danger">{errors.companyName}</p>
                )}
              </div>
              <div className="col-sm-6">
                <input
                  type="email"
                  name="companyEmail"
                  placeholder="Company Email"
                  className="sign-input"
                  value={formData?.companyEmail}
                  onChange={handleChange}
                />
                {errors.companyEmail && (
                  <p className="text-danger">{errors.companyEmail}</p>
                )}
              </div>
            </div>
          )}
          <button
            type="submit"
            className="bg-black-color px-[20px] text-white rounded-lg md:w-[270px] w-full h-[46px] mt-[100px]"
            disabled={loading}
          >
            {loading ? "Updating..." : "Next"}
          </button>
          {error && <p className="text-danger">{error}</p>}
          <p className="mb-0 col-lg-8 mt-2 md:text-base text-[13px]">
            Please make sure that the details you enter here matches the
            documents you will be providing for verification.
          </p>
        </div>
      </form>
    </div>
  );
};

export default IdentificationDetails;
