import {
    FETCH_CART_DATA_SUCCESS,
    FETCH_CART_DATA_FAILURE,
  } from "../actions/cart.actions";
  
  const initialState = {
    cartItems: {},
    error: null,
    loading: false,
  };
  
  const cartReducer = (state = initialState, action) => {
    switch (action.type) {
      case "FETCH_CART_DATA_REQUEST":
        return {
          ...state,
          loading: true,
          error: null,
        };
      case FETCH_CART_DATA_SUCCESS:
        return {
          ...state,
          cartItems: action.payload,
          loading: false,
          error: null,
        };
      case FETCH_CART_DATA_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error,
        };
      default:
        return state;
    }
  };
  
  export default cartReducer;
  