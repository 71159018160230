import React, { useEffect, useMemo } from "react";
import Form from "react-bootstrap/Form";
import { IoCartOutline } from "react-icons/io5";
import { MdFilterList } from "react-icons/md";
import { LuSettings2 } from "react-icons/lu";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";

export default function MarketplaceHeader({
  onSortToggle,
  isAdvertiser,
  count,
  search,
  setSearch,
  noData,
}) {
  const [isSortedByKPR, setIsSortedByKPR] = React.useState(false);
  

  const cartData = useSelector((state) => state.cart.cartItems);

  const countCart = useMemo(() => {
    if (!cartData) return 0;

    const allItems = [
      ...(cartData.adminPackages || []),
      ...(cartData.offerings || []),
      ...(cartData.addOn || []),
      ...(cartData.packages || []),
    ];

    return allItems.reduce((acc, item) => {
      if (item._id || item.id) {
        acc += 1;
      }
      return acc;
    }, 0);
  }, [cartData]);

  const handleSortToggle = (e) => {
    const checked = e.target.checked;
    setIsSortedByKPR(checked);
    onSortToggle(checked);
  };

  const verticalSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    vertical: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    draggable: false,
    verticalSwiping: true,
  };

  return (
    <>
      <div className="sticky top-[100px]">
        <div className="d-lg-flex justify-between">
          <h1 className="font-[590] mb-lg-0 mb-3 text-[31px] leading-[37.2px]">
            Koinpr Marketplace
          </h1>
          <div className="d-lg-none mb-4">
            <div className="row gx-0">
              <div className="col-6">
                <div className="bg-white border-1 border-[#E9E9E9] text-center py-3 sm:text-base text-[12px] flex items-center justify-center gap-3">
                  <MdFilterList />
                  Sort By
                </div>
              </div>
              <div className="col-6">
                <div className="bg-white border-1 border-[#E9E9E9] text-center py-3 sm:text-base text-[12px] flex items-center justify-center gap-3">
                  <LuSettings2 />
                  Filter
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="search-box">
              <input
                className="search-input outline-none"
                type="text"
                placeholder="Search"
                onChange={(e) => setSearch(e.target.value)}
              />
              <button className="search-btn">
                <i className="fas fa-search"></i>
              </button>
            </div>
            {/* <IoBookmarkOutline className="text-[32px] text-[#5E5E5E] d-lg-block d-none" /> */}
            {isAdvertiser && (
              <Link to="/my-cart" className="relative">
                <IoCartOutline className="text-[32px] text-[#5E5E5E] d-lg-block d-none" />
                <span className="bg-black absolute -top-2 -right-2 w-[20px] h-[20px] text-xs shadow rounded-full flex items-center justify-center text-white">
                  {countCart}
                </span>
              </Link>
            )}
          </div>
        </div>

        <div className="include mt-[20px] py-[16px] px-[20px] d-block d-md-none">
          <div>
            <h1 className="font-[590] text-[20px] leading-[24px] text-[#FFFFFF] flex gap-3">
              <img
                src="/assets/images/MarketPlace/list.png"
                className="bg-[#6E6E6E] self-start rounded-lg w-[26px]"
                alt="list"
              />
              We have packages also which includes lots!
            </h1>
          </div>
          <div className="listing">
            <Slider {...verticalSettings} className="text-sm">
              <div>
                <li>lorem ipsum lorem ipsum</li>
              </div>
              <div>
                <li>lorem ipsum lorem ipsum</li>
              </div>
              <div>
                <li>lorem ipsum lorem ipsum</li>
              </div>
              <div>
                <li>lorem ipsum lorem ipsum</li>
              </div>
              <div>
                <li>lorem ipsum lorem ipsum</li>
              </div>
              <div>
                <li>lorem ipsum lorem ipsum</li>
              </div>
              <div>
                <li>lorem ipsum lorem ipsum</li>
              </div>
            </Slider>
          </div>
          <div>
            <button className="font-[510] text-[13px] leading-[15.51px] text-[#1D1D1D] bg-[#FFFFFF] px-[8px] py-[6px] rounded-[4px] w-[100%] mt-[12px]">
              Let’s Explore
            </button>
          </div>
        </div>

        <div className="d-lg-flex d-none items-end justify-between mt-[21px]">
          <div>
            <h4 className="font-[600] text-[16px] leading-[19.36px] ">
              Showing Result:{" "}
              <span className="font-[600] text-[16px] leading-[19.36px] text-[#3772FF]">
                {noData ? "0" : count}
              </span>
            </h4>
          </div>
          <div className="flex buttons items-center gap-2">
            {/* <div className="relative me-3">
              <BsCart3 className="w-[24px] h-[24px]" />
              <span className="bg-black absolute -top-3 -right-3 w-[20px] h-[20px] text-xs shadow rounded-full flex items-center justify-center text-white">0</span>
            </div> */}
            <div className="suggest_btn">
              <button className="font-[590] text-[16px] leading-[19.2px] text-[#000000] flex items-center gap-2 px-[20px] py-[15px]">
                <img src="/assets/images/MarketPlace/group.png" alt="" />{" "}
                Hire Conversions Expert
              </button>
            </div>
            <button className="font-[590] text-[16px] bg-white border border-[#DBDBDB] rounded-lg leading-[19.2px] text-[#000000] flex items-center gap-8 px-[20px] py-[15px]">
              KPR Metric
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  checked={isSortedByKPR}
                  onChange={handleSortToggle}
                />
              </Form>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
