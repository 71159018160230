import React, { useEffect, useMemo, useState } from "react";
import { FaArrowLeft, FaXTwitter } from "react-icons/fa6";
import { PiArrowSquareOutLight } from "react-icons/pi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GrFacebookOption } from "react-icons/gr";
import { AiFillInstagram } from "react-icons/ai";
import { IoCartOutline, IoLogoLinkedin } from "react-icons/io5";
import { FaYoutube } from "react-icons/fa";
import { IoLogoTiktok } from "react-icons/io5";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaTelegramPlane } from "react-icons/fa";
import PopluarPackage from "./PopluarPackage";
import PressRelease from "./PressRelease";
import MarketplaceBrand from "../Marketplace/MarketplaceBrand.jsx";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import useCategories from "../../hooks/useCategories.js";
import BannerExample1 from "./BannerExample1.js";

const TodayqNews = () => {
  const location = useLocation();
  const { state } = location;
  const [isAdvertiser, setIsAdvertiser] = useState(false);
  console.log(state);

  const [selectedPackage, setSelectedPackage] = useState(
    state?.offeringCategory?._id
  );
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.authentication);

  const { categories } = useCategories();
  useEffect(() => {
    if (user?.userType !== "publisher") setIsAdvertiser(true);
    else setIsAdvertiser(false);
  }, [isAdvertiser, user]);

  const cartData = useSelector((state) => state.cart.cartItems);

  const countCart = useMemo(() => {
    if (!cartData) return 0;

    const allItems = [
      ...(cartData.adminPackages || []),
      ...(cartData.offerings || []),
      ...(cartData.addOn || []),
      ...(cartData.packages || []),
    ];

    return allItems.reduce((acc, item) => {
      if (item._id || item.id) {
        acc += 1;
      }
      return acc;
    }, 0);
  }, [cartData]);

  return (
    <div className="today_news">
      <div className="md:px-[32px] px-[12px] py-[24px]">
        <div className="flex justify-between">
          <div className="flex items-center mb-5">
            <button onClick={() => navigate(-1)} className="text-black">
              <FaArrowLeft className="me-3" />
            </button>
            <h3 className="font-[590] text-[24px] mb-0">Product Details</h3>
          </div>
          {isAdvertiser && (
            <Link to="/my-cart" className="relative">
              <IoCartOutline className="text-[32px] text-[#5E5E5E] d-lg-block d-none" />
              <span className="bg-black absolute -top-2 -right-2 w-[20px] h-[20px] text-xs shadow rounded-full flex items-center justify-center text-white">
                {countCart}
              </span>
            </Link>
          )}
        </div>

        <div className="row">
          <div className="col-xl-9 col-lg-8">
            <div className="bg-white shadows mb-4 p-4 rounded-lg border-1 border-[#DBDBDB]">
              <div className="d-flex justify-between items-center mb-4">
                <div className="d-flex items-center gap-3">
                  <img
                    src={
                      state?.basicDetails?.["Website LOGO"] ||
                      "/assets/images/Home-Page/marketPlace1.png"
                    }
                    alt=""
                  />
                  <div>
                    <h2 className="font-[590] md:text-[24px] text-[20px]">
                      {state?.basicDetails?.websiteName ?? "No Name"}
                    </h2>
                    <span className="text-[13px] font-[510] flex items-center gap-1">
                      {state?.basicDetails?.websiteURL && (
                        <a
                          target="_blank"
                          href={state?.basicDetails?.websiteURL}
                          rel="noreferrer"
                        >
                          {state?.basicDetails?.websiteURL}
                        </a>
                      )}
                      {state?.basicDetails?.websiteURL && (
                        <PiArrowSquareOutLight className="text-[#3772FF]" />
                      )}
                    </span>
                  </div>
                </div>
                <div className="d-md-flex d-none items-end gap-2">
                  <div>
                    <select
                      className="border outline-none rounded-lg px-[20px] h-[46px] "
                      value={selectedPackage}
                      onChange={(e) => {
                        setSelectedPackage(e.target.value);
                      }}
                    >
                      <option value="" key="" disabled>
                        Choose Category
                      </option>
                      {categories?.map((category) => (
                        <option value={category?._id} key={category?._id}>
                          {category?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <button
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasBottom1"
                    className="bg-black border outline-none rounded-lg px-[20px] h-[46px] text-white"
                  >
                    See Case Study
                  </button>
                  <BannerExample1 state={state?.basicDetails} />
                  <div>
                    <img src="/assets/images/expanded/share.png" alt="" />
                  </div>
                  {/* <div>
                    <img src="/assets/images/expanded/save.png" alt="" />
                  </div> */}
                </div>
                <div className="d-md-none">
                  <BsThreeDotsVertical className="text-[24px]" />
                </div>
              </div>
              <div className="d-flex items-center gap-3 border-b pb-3 mb-3">
                <div className="bg-[#F0EBFD] border-1 w-[138px] text-center p-2 rounded-lg border-[#DBDBDB]">
                  <span className="font-[510] text-[13px]">Visitors</span>
                  <p className="mb-0 font-[590] text-[20px]">1.5M</p>
                </div>
                <div className="bg-[#EAF6FD] border-1 w-[138px] text-center p-2 rounded-lg border-[#DBDBDB]">
                  <span className="font-[510] text-[13px]">Metric 2</span>
                  <p className="mb-0 font-[590] text-[20px]">4.9M</p>
                </div>
                <div className="bg-[#EAF7F0] border-1 w-[138px] text-center p-2 rounded-lg border-[#DBDBDB]">
                  <span className="font-[510] text-[13px]">KPR Metric</span>
                  <p className="mb-0 font-[590] text-[20px]">
                    {state?.kprMetric}/ 10
                  </p>
                </div>
              </div>
              <div className="mb-4">
                <h3 className="md:text-[20px] text-base font-[590] mb-4">
                  {state?.basicDetails?.websiteName}
                </h3>
                <p className="mb-0">
                  {state?.basicDetails?.websiteDescription}
                </p>
              </div>
              <div className="row gy-4">
                <div className="col-md-3">
                  <h3 className="md:text-[20px] text-base font-[590] mb-md-4 mb-2">
                    Social Media
                  </h3>
                  <div className="flex items-center  flex-wrap text-xl gap-2 text-[#434343]">
                    {state?.basicDetails?.socialLinks?.map((link) => {
                      switch (link?.name) {
                        case "Facebook":
                          return (
                            <GrFacebookOption
                              className="cursor-pointer"
                              key={link.name}
                              onClick={() => {
                                window.open(link?.link, "_blank");
                              }}
                            />
                          );
                        case "X":
                          return (
                            <FaXTwitter
                              key={link.name}
                              className="cursor-pointer"
                              onClick={() => {
                                window.open(link?.link, "_blank");
                              }}
                            />
                          );
                        case "Youtube":
                          return (
                            <FaYoutube
                              className="cursor-pointer"
                              key={link.name}
                              onClick={() => {
                                window.open(link?.link, "_blank");
                              }}
                            />
                          );
                        case "Instagram":
                          return (
                            <AiFillInstagram
                              className="cursor-pointer"
                              key={link.name}
                              onClick={() => {
                                window.open(link?.link, "_blank");
                              }}
                            />
                          );
                        case "LinkedIn":
                          return (
                            <IoLogoLinkedin
                              key={link.name}
                              className="cursor-pointer"
                              onClick={() => {
                                window.open(link?.link, "_blank");
                              }}
                            />
                          );
                        case "WhatsApp":
                          return (
                            <IoLogoWhatsapp
                              key={link.name}
                              className="cursor-pointer"
                              onClick={() => {
                                window.open(link?.link, "_blank");
                              }}
                            />
                          );

                        case "Telegram":
                          return (
                            <FaTelegramPlane
                              key={link.name}
                              className="cursor-pointer"
                              onClick={() => {
                                window.open(link?.link, "_blank");
                              }}
                            />
                          );

                        case "TikTok":
                          return (
                            <IoLogoTiktok
                              key={link.name}
                              className="cursor-pointer"
                              onClick={() => {
                                window.open(link?.link, "_blank");
                              }}
                            />
                          );

                        default:
                          return null;
                      }
                    })}
                  </div>
                </div>
                <div className="col-md-5">
                  <h3 className="md:text-[20px] text-base font-[590] mb-md-4 mb-2">
                    Regions
                  </h3>
                  <p className="mb-0">
                    {Array.isArray(state?.basicDetails?.geoLocations) &&
                      state?.basicDetails?.geoLocations?.join(", ")}
                  </p>
                </div>
                <div className="col-md-4">
                  <h3 className="md:text-[20px] text-base font-[590] mb-md-4 mb-2">
                    Languages
                  </h3>
                  <p className="mb-0 capitalize">
                    {state?.basicDetails?.contentLanguage}
                  </p>
                </div>
              </div>
            </div>
            <PopluarPackage
              isAdvertiser={isAdvertiser}
              selectedPackage={selectedPackage}
            />

            <div className="include my-[20px] py-[16px] px-[20px] d-lg-none">
              <div>
                <h1 className="font-[590] text-[20px] leading-[24px] text-[#FFFFFF] flex gap-3">
                  <img
                    src="/assets/images/MarketPlace/list.png"
                    className="bg-[#6E6E6E] self-start rounded-lg w-[26px]"
                    alt="list"
                  />
                  We have packages also which includes lots!
                </h1>
              </div>
              <div className="listing">
                <ul className="list-disc list-inside">
                  <li>lorem ipsum lorem ipsum</li>
                  <li>lorem ipsum lorem ipsum</li>
                  <li>lorem ipsum lorem ipsum</li>
                  <li>lorem ipsum lorem ipsum</li>
                  <li>lorem ipsum lorem ipsum</li>
                </ul>
              </div>
            </div>
            <PressRelease isAdvertiser={isAdvertiser} state={state} />
          </div>
          <div className="col-xl-3 col-lg-4">
            <MarketplaceBrand />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TodayqNews;
