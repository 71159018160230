import React from 'react'
import Header from '../../layout/headers/header'
import MyCart from './MyCart'

const Index = () => {
  return (
    <div>
      <MyCart/>
    </div>
  )
}

export default Index
