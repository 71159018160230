import React, { useRef, useState, useEffect } from "react";
import { CiEdit } from "react-icons/ci";
import { FaRegFileAlt } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import { TiDelete } from "react-icons/ti";
import * as Yup from "yup";
import useGeo from "../../../hooks/useGeo";
import { MultiSelect } from "react-multi-select-component";
import toast from "react-hot-toast";

const TikTokInfluencers = ({
  values,
  handleFieldValue,
  errors,
  setFieldValue,
  setValidationSchema,
}) => {
  const inputRef = useRef(null);
  const inputLogoRef = useRef(null);
  const { countries, lang } = useGeo();
  const onButtonClickLogo = () => {
    inputLogoRef.current.click();
  };

  useEffect(() => {
    setValidationSchema(
      Yup.object().shape({
        websiteName: Yup.string().required("Required"),
        websiteURL: Yup.string().required("Required"),
        websiteDescription: Yup.string().required("Required"),
        logo: Yup.mixed().required("Required"),
        geoLocations: Yup.array()
          .required("Required")
          .min(1, "You must provide minimum 1 locations")
          .max(5, "You must provide exactly 5 locations"),
        country: Yup.string().required("Required"),
        telegramID: Yup.string().required("Required"),
        contentLanguage: Yup.string().required("Required"),
        gambling: Yup.string().required("Required"),
        adultContent: Yup.string().required("Required"),
        crypto: Yup.string().required("Required"),
        // files: Yup.array()
        //   .min(1, "Please upload at one file")
        //   .required("Required"),
      })
    );
  }, [setValidationSchema]);

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const channelTopic = [
    {
      label: "Trading",
      value: "Trading",
      _id: "Trading",
    },
    {
      label: "NFT",
      value: "NFT",
      _id: "NFT",
    },
    {
      label: "DeFi",
      value: "DeFi",
      _id: "DeFi",
    },
    {
      label: "Crypto/Web3",
      value: "Crypto/Web3",
      _id: "Crypto/Web3",
    },
    {
      label: "Blockchain",
      value: "Blockchain",
      _id: "Blockchain",
    },
    {
      label: "Giveaways",
      value: "Giveaways",
      _id: "Giveaways",
    },
    {
      label: "Project Introduction",
      value: "Project Introduction",
      _id: "Project Introduction",
    },
    {
      label: "ICOs",
      value: "ICOs",
      _id: "ICOs",
    },
    {
      label: "Project Review",
      value: "Project Review",
      _id: "Project Review",
    },
  ];

  console.log({ errors });

  return (
    <>
      <div className="mb-2 col-md-6">
        <div className="dropdown rounded-lg bg-white mb-3">
          <div className="w-[100%]">
            <label
              htmlFor="websiteName"
              className="font-[510] text-[14px] leading-[21px] text-[#474747]"
            >
              TikTok Handle Name
            </label>
            <br />
            <input
              autoFocus
              type="text"
              name="websiteName"
              onChange={handleFieldValue}
              value={values.websiteName || ""}
              id="websiteName"
              className="w-[100%] py-[12px] px-[13px] rounded-[8px] mt-2 font-[510] text-[14px] leading-[21px] border-1 border-[#DBDBDB] Outline"
              placeholder="Todayq"
            />
            {errors.websiteName && (
              <span className="text-sm text-red-500">{errors.websiteName}</span>
            )}
          </div>
        </div>
      </div>

      <div className="mb-2 col-md-6">
        <div className="dropdown rounded-lg bg-white mb-3">
          <div className="w-[100%]">
            <label
              htmlFor="websiteURL"
              className="font-[510] text-[14px] leading-[21px] text-[#474747]"
            >
              Profile URL
            </label>{" "}
            <br />
            <input
              type="text"
              name="websiteURL"
              onChange={handleFieldValue}
              value={values.websiteURL || ""}
              id="websiteURL"
              className="w-[100%] py-[12px] px-[13px] rounded-[8px] mt-2 font-[510] text-[14px] leading-[21px] border-1 border-[#DBDBDB] Outline"
              placeholder="http/todayq.com"
            />
            {errors.websiteURL && (
              <span className="text-sm text-red-500">{errors.websiteURL}</span>
            )}
          </div>
        </div>
      </div>

      <div className="col-lg-12">
        <div className="dropdown rounded-lg bg-white mb-3">
          <div className="w-[100%]">
            <label
              htmlFor="websiteDescription"
              className="font-[510] text-[14px] leading-[21px] text-[#474747]"
            >
              Description
            </label>{" "}
            <br />
            <textarea
              name="websiteDescription"
              onChange={handleFieldValue}
              value={values.websiteDescription || ""}
              id="websiteDescription"
              className="w-[100%] py-[12px] px-[13px] rounded-[8px] mt-2 font-[510] text-[14px] leading-[21px] border-1 border-[#DBDBDB] Outline"
              rows="4"
              cols="50"
              placeholder="Type description here...."
            />
            {errors.websiteDescription && (
              <span className="text-sm text-red-500">
                {errors.websiteDescription}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="mb-2 col-md-6">
        <div className="dropdown rounded-lg bg-white mb-3">
          <div className="w-[100%]">
            <label
              htmlFor="followers"
              className="font-[510] text-[14px] leading-[21px] text-[#474747]"
            >
              Followers
            </label>{" "}
            <br />
            <input
              type="number"
              name="followers"
              onChange={handleFieldValue}
              value={values.followers}
              placeholder="00"
              id="followers"
              className="w-[100%] py-[12px] px-[13px] rounded-[8px] mt-2 font-[510] text-[14px] leading-[21px] border-1 border-[#DBDBDB] Outline"
            />
            {errors.followers && (
              <span className="text-sm text-red-500">{errors.followers}</span>
            )}
          </div>
        </div>
      </div>

      {/* <div className="mb-2 col-md-6">
        <div className="dropdown rounded-lg bg-white mb-3">
          <div className="w-[100%]">
            <label
              htmlFor="kprMetric"
              className="font-[510] text-[14px] leading-[21px] text-[#474747]"
            >
              KPR metric (Admin only)
            </label>{" "}
            <br />
            <input
              type="text"
              name="kprMetric"
              className="w-[100%] py-[12px] px-[13px] rounded-[8px] mt-2 font-[510] text-[14px] leading-[21px] border-1 border-[#DBDBDB] Outline"
              placeholder="Enter metric"
              onChange={handleInputChange}
              required
            />
          </div>
        </div>
      </div> */}

      <div className="mt-2 relative">
        <label className="font-[510] text-[18px] leading-[27px] mb-0 text-[#0A0A0A]">
          Influencer LOGO
        </label>

        <div
          className={`image_upload my-2 ${values?.logo && "opacity-0"}`}
          style={{
            border: "2px dashed #DBDBDB",
            padding: "20px",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <input
            ref={inputLogoRef}
            type="file"
            id="input-file-upload"
            style={{ display: "none" }}
            onChange={(e) => {
              const file = e.currentTarget.files[0];
              setFieldValue(
                "logo",
                Object.assign(file, {
                  preview: URL.createObjectURL(file),
                })
              );
            }}
          />
          <div onClick={onButtonClickLogo} className="font-[300]">
            <img
              src="/assets/images/account/upload.png"
              className="mx-auto mb-3 w-[90px]"
              alt="upload"
            />
            <p className="md:text-base text-[13px] font-[590] mb-1">
              Browse Files to upload
            </p>
            <div className="text-[12px]">
              Max file size 5 MB. Supported file types: png, jpeg, pdf, doc.
            </div>
          </div>
        </div>
        {values.logo && (
          <div className="font-[300] w-[100%] !p-0 z-20 absolute top-[20%]">
            <img
              src={
                values.logo
                  ? values.logo.preview
                  : "/assets/images/Home-Page/DodoBigimage.png"
              }
              className="mx-auto py-2 mb-3 w-[90px]"
              alt="upload"
            />
            <div className="bg-[#DBDBDB] flex justify-between w-[100%] py-[10px] px-[20px]">
              <div
                onClick={onButtonClickLogo}
                className="flex items-center gap-2 cursor-pointer"
              >
                <CiEdit className="text-xl" />
                <p className="!text-[#0A0A0A] font-[400] text-[20px] leading-[23.5px] mb-0">
                  Edit
                </p>
              </div>
              <div
                onClick={() => {
                  setFieldValue("logo", "");
                }}
                className="flex items-center gap-2 cursor-pointer"
              >
                <RiDeleteBin6Line className="text-xl" />
                <p className="!text-[#0A0A0A] font-[400] text-[20px] leading-[23.5px] mb-0">
                  Remove
                </p>
              </div>
            </div>
          </div>
        )}

        {errors.logo && (
          <span className="text-sm text-red-500">{errors.logo}</span>
        )}
      </div>
      <p className="font-bold text-[18px] mb-0 my-2 text-[#474747]">
        {" "}
        Add Social Link{" "}
      </p>
      <div className="mb-2 col-md-6">
        <label>Link Title</label>
      </div>
      <div className="mb-2 col-md-6">
        <label>Link</label>
      </div>
      {values?.socialLinks?.map((item, index) => (
        <React.Fragment>
          <div className="col-md-6  my-1">
            <div className="dropdown rounded-lg bg-white">
              <div className="w-[100%]">
                <select
                  name="name"
                  className="w-[100%] py-[12px] px-[13px] rounded-[8px]  border-1 border-[#DBDBDB] Outline"
                  id="name"
                  onChange={(e) => {
                    values.socialLinks[index].name = e.target.value;
                    setFieldValue("socialLinks", values.socialLinks);
                  }}
                  value={item.name || ""}
                >
                  <option
                    value=""
                    className="font-[400] text-[14px] leading-[21px] text-[#474747]"
                  >
                    Select option
                  </option>
                  <option value="Facebook">Facebook</option>
                  <option value="X">X</option>
                  <option value="Instagram">Instagram</option>
                  <option value="LinkedIn">LinkedIn</option>
                  <option value="Youtube">Youtube</option>
                  <option value="TikTok">TikTok</option>
                  <option value="WhatsApp">WhatsApp</option>
                  <option value="Telegram">Telegram</option>
                </select>
                {errors.name && (
                  <span className="text-sm text-red-500">{errors.name}</span>
                )}
              </div>
            </div>
            {errors.socialLinks ? (
              errors.socialLinks[index] ? (
                errors.socialLinks[index].name ? (
                  <span className="text-sm text-red-500">
                    {errors.socialLinks[index].name}
                  </span>
                ) : null
              ) : null
            ) : null}
          </div>
          <div className="col-md-6 my-1">
            <div className="flex gap-x-2">
              <input
                type="text"
                name="link"
                value={item.link}
                onChange={(e) => {
                  values.socialLinks[index].link = e.target.value;
                  setFieldValue("socialLinks", values.socialLinks);
                }}
                className="w-[100%] py-[12px] px-[13px] rounded-[8px] font-[510] text-[14px] leading-[21px] border-1 border-[#DBDBDB] Outline"
                placeholder="Enter Link"
              />
              {errors.socialLinks ? (
                errors.socialLinks[index] ? (
                  errors.socialLinks[index].link ? (
                    <span className="text-sm text-red-500">
                      {errors.socialLinks[index].link}
                    </span>
                  ) : null
                ) : null
              ) : null}
              {values.socialLinks.length > 1 && (
                <div className="flex col-1 items-center justify-center">
                  <button
                    type="button"
                    className="text-[22px]"
                    onClick={() => {
                      const newLinks = [...values.socialLinks];
                      newLinks.splice(index, 1);
                      setFieldValue("socialLinks", newLinks);
                    }}
                  >
                    <TiDelete />
                  </button>
                </div>
              )}
            </div>
          </div>
        </React.Fragment>
      ))}
      <div className="flex justify-end">
        <button
          type="button"
          className="bg-[#0A0A0A] text-[14px] leading-[21px] text-[#fff] py-2 px-2 rounded-[8px]"
          onClick={() => {
            setFieldValue("socialLinks", [
              ...values.socialLinks,
              {
                name: "",
                link: "",
              },
            ]);
          }}
        >
          + Add new link
        </button>
      </div>

      <div className="col-md-6">
        <div className="dropdown rounded-lg bg-white mb-3">
          <div className="w-[100%]">
            <label
              htmlFor="geoLocations"
              className="font-[510] text-[14px] leading-[21px] text-[#474747]"
            >
              Top 5 GEOs
            </label>{" "}
            <br />
            <MultiSelect
              hasSelectAll={false} // This removes the "Select All" option
              options={
                Array.isArray(countries) &&
                countries?.length > 0 &&
                countries?.map((item) => {
                  return {
                    _id: item?.name?.common,
                    label: item?.name?.common,
                    value: item?.name?.common,
                  };
                })
              }
              value={
                (Array.isArray(values?.geoLocations) &&
                  values.geoLocations?.length > 0 &&
                  values?.geoLocations?.map((i) => {
                    return {
                      _id: i,
                      label: i,
                      value: i,
                    };
                  })) ||
                []
              }
              onChange={(e) => {
                if (e.length > 5) {
                  toast.error("Can't Select More Than 5 Countries");
                  return;
                }
                setFieldValue(
                  "geoLocations",
                  e?.map((i) => i.value)
                );
              }}
              labelledBy="Select"
            />
            {errors.geoLocations && (
              <span className="text-sm text-red-500">
                {errors.geoLocations}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="col-md-6">
        <div className="dropdown rounded-lg bg-white mb-3">
          <div className="w-[100%]">
            <label
              htmlFor="contentLanguage"
              className="font-[510] text-[14px] leading-[21px] text-[#474747]"
            >
              Profile Content Language
            </label>{" "}
            <br />
            <select
              name="contentLanguage"
              className="w-[100%] py-[12px] px-[13px] rounded-[8px] mt-2 border-1 border-[#DBDBDB] Outline"
              id="contentLanguage"
              onChange={handleFieldValue}
              value={values.contentLanguage || ""}
            >
              <option
                className="font-[400] text-[14px] leading-[21px] text-[#474747]"
                disabled
                value=""
              >
                Choose Profile Content Language
              </option>
              {Array.isArray(lang) &&
                lang.map((item, index) => (
                  <option key={item?._id || index} value={item?.name}>
                    {item.name}
                  </option>
                ))}
            </select>
            {errors.contentLanguage && (
              <span className="text-sm text-red-500">
                {errors.contentLanguage}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="col-md-6">
        <div className="dropdown rounded-lg bg-white mb-3">
          <div className="w-[100%]">
            <label
              htmlFor="country"
              className="font-[510] text-[14px] leading-[21px] text-[#474747]"
            >
              Which Country Your Website is Based
            </label>{" "}
            <br />
            <select
              name="country"
              className="w-[100%] py-[12px] px-[13px] rounded-[8px] mt-2 border-1 border-[#DBDBDB] Outline"
              id="country"
              onChange={handleFieldValue}
              value={values.country || ""}
            >
              <option
                className="font-[400] text-[14px] leading-[21px] text-[#474747]"
                disabled
                value=""
              >
                Choose Country
              </option>
              {Array.isArray(countries) &&
                countries.map((item, index) => (
                  <option
                    key={item?._id || index}
                    value={item?.name?.common}
                    data-id={item.name}
                  >
                    {item.name?.common}
                  </option>
                ))}
            </select>
            {errors.country && (
              <span className="text-sm text-red-500">{errors.country}</span>
            )}
          </div>
        </div>
      </div>

      <div className="mb-2 col-md-6">
        <div className="dropdown rounded-lg bg-white">
          <div className="w-[100%]">
            <label
              htmlFor="telegramID"
              className="font-[510] text-[14px] leading-[21px] text-[#474747]"
            >
              Personal Telegram ID (For Order Delivery)
            </label>{" "}
            <br />
            <input
              type="text"
              name="telegramID"
              onChange={handleFieldValue}
              value={values.telegramID || ""}
              id="telegramID"
              className="w-[100%] py-[12px] px-[13px] rounded-[8px] mt-2 font-[510] text-[14px] leading-[21px] border-1 border-[#DBDBDB] Outline"
              placeholder="Type Telegram ID here...."
            />
            {errors.telegramID && (
              <span className="text-sm text-red-500">{errors.telegramID}</span>
            )}
          </div>
        </div>
      </div>

      <div className="col-lg-12">
        <p className="font-bold text-[18px] mb-0 my-2 text-[#474747]">
          {" "}
          Allowed Content Type{" "}
        </p>
        <div className="row">
          <div className="col-md-4">
            <div className="dropdown rounded-lg bg-white">
              <div className="w-[100%]">
                <label
                  htmlFor="gambling"
                  className="font-[510] text-[14px] leading-[21px] text-[#474747]"
                >
                  Gambling
                </label>
                <br />
                <select
                  name="gambling"
                  className="w-[100%] py-[12px] px-[13px] rounded-[8px] mt-2 border-1 border-[#DBDBDB] Outline"
                  id="gambling"
                  onChange={handleFieldValue}
                  value={values.gambling || ""}
                >
                  <option
                    value=""
                    className="font-[400] text-[14px] leading-[21px] text-[#474747]"
                  >
                    Select option
                  </option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
                {errors.gambling && (
                  <span className="text-sm text-red-500">
                    {errors.gambling}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="dropdown rounded-lg bg-white">
              <div className="w-[100%]">
                <label
                  htmlFor="adultContent"
                  className="font-[510] text-[14px] leading-[21px] text-[#474747]"
                >
                  Adult Content
                </label>{" "}
                <br />
                <select
                  name="adultContent"
                  className="w-[100%] py-[12px] px-[13px] rounded-[8px] mt-2 border-1 border-[#DBDBDB] Outline"
                  id="adultContent"
                  onChange={handleFieldValue}
                  value={values.adultContent || ""}
                >
                  <option
                    value=""
                    className="font-[400] text-[14px] leading-[21px] text-[#474747]"
                  >
                    Select option
                  </option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
                {errors.adultContent && (
                  <span className="text-sm text-red-500">
                    {errors.adultContent}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="dropdown rounded-lg bg-white">
              <div className="w-[100%]">
                <label
                  htmlFor="crypto"
                  className="font-[510] text-[14px] leading-[21px] text-[#474747]"
                >
                  Crypto/Web3.0
                </label>{" "}
                <br />
                <select
                  name="crypto"
                  className="w-[100%] py-[12px] px-[13px] rounded-[8px] mt-2 border-1 border-[#DBDBDB] Outline"
                  id="crypto"
                  onChange={handleFieldValue}
                  value={values.crypto || ""}
                >
                  <option
                    value=""
                    className="font-[400] text-[14px] leading-[21px] text-[#474747]"
                  >
                    Select option
                  </option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
                {errors.crypto && (
                  <span className="text-sm text-red-500">{errors.crypto}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="dropdown rounded-lg bg-white">
          <div className="w-[100%] my-2">
            <label
              htmlFor="channel"
              className="font-[510] text-[14px] leading-[21px] text-[#474747]"
            >
              Choose Your Profile Topics
            </label>{" "}
            <br />
            <MultiSelect
              hasSelectAll={false} // This removes the "Select All" option
              options={
                Array.isArray(channelTopic) &&
                channelTopic?.length > 0 &&
                channelTopic
              }
              value={
                (Array.isArray(values?.channel) &&
                  values.channel?.length > 0 &&
                  values?.channel?.map((i) => {
                    return {
                      _id: i,
                      label: i,
                      value: i,
                    };
                  })) ||
                []
              }
              onChange={(e) => {
                if (e.length > 5) {
                  toast.error("Can't Select More Than 5 Countries");
                  return;
                }
                setFieldValue(
                  "channel",
                  e?.map((i) => i.value)
                );
              }}
              labelledBy="Select"
            />
            {errors.channel && (
              <span className="text-sm text-red-500">{errors.channel}</span>
            )}
          </div>
        </div>
      </div>

      <div className="mt-2">
        <label
          htmlFor=""
          className="font-[510] text-[14px] leading-[21px] text-[#474747]"
        >
          Upload Case Study
        </label>
        <div
          className="image_upload my-2"
          style={{
            border: "2px dashed #DBDBDB",
            padding: "20px",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <input
            ref={inputRef}
            type="file"
            id="input-file-upload"
            multiple
            style={{ display: "none" }}
            onChange={(e) => {
              setFieldValue(
                "files",
                Array.from(e.currentTarget.files).map((file) =>
                  Object.assign(file, {
                    preview: URL.createObjectURL(file),
                  })
                )
              );
            }}
          />
          <div onClick={onButtonClick} className="font-[300]">
            <img
              src="/assets/images/account/upload.png"
              className="mx-auto mb-3 w-[90px]"
              alt="upload"
            />
            <p className="md:text-base text-[13px] font-[590] mb-1">
              Browse Files to upload
            </p>
            <div className="text-[12px]">
              Max file size 5 MB. Supported file types: png, jpeg, pdf, doc.
            </div>
          </div>
        </div>
        {(values.files || []).map((file) => (
          <div
            key={file.name}
            className="file-item d-flex items-center justify-between mb-2 bg-[#F1F8FF] p-3 rounded-lg"
          >
            <p className="file-name mb-0 d-flex items-center gap-3">
              <FaRegFileAlt />
              {file.name}
            </p>
            <button
              onClick={() =>
                setFieldValue(
                  "files",
                  values.files.filter((f) => f !== file)
                )
              }
              type="button"
              className="remove-button text-[#FF543E] text-xl"
            >
              <RiDeleteBin6Line />
            </button>
          </div>
        ))}
        {errors.files && (
          <span className="text-sm text-red-500">{errors.files}</span>
        )}
      </div>
    </>
  );
};

export default TikTokInfluencers;
