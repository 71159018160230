import React from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import Accordion from "react-bootstrap/Accordion";
import { useSelector } from "react-redux";

const Drafts = ({ setDataIndex, selectedStep, setSelectedStep }) => {
  const { offeringData } = useSelector((state) => state.offerings);

  return (
    <>
      <div className="flex justify-between items-center drafts">
        <p className="text-[#000000] font-[590] text-[18px] mb-0 leading-[21.48px]">
          Your offering drafts
        </p>
        <IoMdInformationCircleOutline className="w-[21.53] h-[21.53]" />
      </div>

      {offeringData?.category && (
        <div className="mt-4">
          <Accordion className="!bg-[#DBDBDB] rounded-[9px] overflow-hidden">
            <Accordion.Item
              eventKey="0"
              className="!border-1 !rounded-[9px] !border-[#DBDBDB]"
            >
              <Accordion.Header className="m-0 !font-[400] !bg-[#DBDBDB] rounded-[8px] !py-[12px] !px-[8px] !text-[18px] !text-[#3C3C3C] !leading-[21.48px] ">
                {offeringData?.category}
              </Accordion.Header>
              <Accordion.Body className="visible !text-[#3C3C3C] !font-[400] !text-[16px] !leading-[24px]">
                {Array.isArray(offeringData?.contentOfferings) &&
                  offeringData?.contentOfferings?.map((_, index) => (
                    <p
                      className="cursor-pointer"
                      onClick={() => {
                        if (selectedStep === "Add Offering") {
                          setSelectedStep("Add Content Offerings");
                        }
                        if (selectedStep === "Add Content Offerings") {
                          setDataIndex(index);
                        }
                      }}
                      key={index}
                    >
                      {_?.offeringName}
                    </p>
                  ))}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )}
    </>
  );
};

export default Drafts;
