import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./style/index.scss";
import "./App.css";
import SignUp from "./pages/sign-up";
import SignIn from "./pages/SignIn";
import AccountVerification from "./pages/AccountVerification";
import GetStarted from "./pages/GetStarted";
import CongratulationsPop from "./pages/Congratulations";
import CreatePackage from "./pages/dashboard/CreatePackage";
import Dashboard from "./pages/Dashboard";
import AddOffering from "./pages/AddOffering";
import AdvertiserSignUp from "./pages/Advertiser/AdvertiserSignUp";
import AdvertiserSignIn from "./pages/Advertiser/AdvertiserSignIn";
import ExpandedView from "./pages/Advertiser/ExpandedView";
import Marketplace from "./pages/Marketplace";
import Package from "./pages/package";
import AdvertiserVerification from "./pages/Advertiser/AdvertiserVerification";
import MyCart from "./pages/MyCart";
import MyPackages from "./pages/MyPackages";
import MyOfferings from "./pages/MyOfferings";
import { Toaster } from "react-hot-toast";
import DefaultPage from "./pages/defaultPage";
import PaymentHistory from "./pages/PaymentHistory";
import MyBilling from "./pages/MyBilling";
import Protected from "./Components/Protected";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchCartData } from "./redux/actions/cart.actions";
// import Test from "./test";

function App() {
  const dispatch = useDispatch();
  const storeUserData = useSelector((state) => state.authentication.user);
  const user = JSON.parse(localStorage.getItem("user")) || storeUserData;
  useEffect(() => {
    dispatch(fetchCartData());
  }, [dispatch]);
  return (
    <>
      <Toaster />

      <BrowserRouter>
        <Routes>
          {/* <Route path="/test" element={<Test />}></Route> */}
          <Route path="/" element={<DefaultPage />}></Route>
          <Route path="/Sign-up" element={<SignUp />}></Route>
          <Route path="/sign-in" element={<SignIn />}></Route>
          <Route path="/get-started" element={<GetStarted />}></Route>
          <Route
            path="/congratulation"
            element={<CongratulationsPop />}
          ></Route>
          <Route
            path="/account-verification"
            element={<AccountVerification />}
          ></Route>
          {user?.userType === "publisher" && (
            <Route path="/Dashboard" element={<Dashboard />}></Route>
          )}
          <Route path="/Marketplace" element={<Marketplace />}></Route>
          <Route path="/Package" element={<Package />}></Route>
          <Route path="/AddOffering" element={<AddOffering />}></Route>
          <Route path="/create-package" element={<CreatePackage />}></Route>
          <Route
            path="/advertiser-signup"
            element={<AdvertiserSignUp />}
          ></Route>
          <Route
            path="/advertiser-signin"
            element={<AdvertiserSignIn />}
          ></Route>
          <Route
            path="/advertiser-verification"
            element={<AdvertiserVerification />}
          ></Route>
          <Route path="/view/:id" element={<ExpandedView />}></Route>

          <Route
            path="/my-cart"
            element={
              <Protected>
                <MyCart />{" "}
              </Protected>
            }
          ></Route>

          <Route path="/my-package" element={<MyPackages />}></Route>
          <Route path="/my-offerings" element={<MyOfferings />}></Route>
          <Route
            path="/my-billing"
            element={
              <Protected>
                <MyBilling />
              </Protected>
            }
          ></Route>
          <Route path="/payment-history" element={<PaymentHistory />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
