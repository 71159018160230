// import React from "react";
// import { BiEditAlt } from "react-icons/bi";
// import { FaRegFileAlt } from "react-icons/fa";
// import { Link } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";
// import { submitForm } from "../../redux/actions/auth.action";

// const Review = () => {
//   const dispatch = useDispatch();
//   const { formData } = useSelector((state) => state.authentication);

//   console.log(formData, "formDataformDataformDataformDataformDataformData");
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     dispatch(submitForm(formData)); // Dispatch action to submit the form
//   };

//   return (
//     <div className="identification_details bg-white border-1 border-[#DBDBDB] rounded-lg py-3 md:px-[30px] px-[16px]">
//       <h3 className="md:text-[20px] text-base font-[590]">Review</h3>
//       <p className="mb-5">
//         Your details will be used for billing and generating invoice
//       </p>

//       <div>
//         <div className="flex items-center justify-between mb-4">
//           <h3 className="md:text-[24px] text-[20px] font-[590]">
//             Identification Details
//           </h3>
//           <span className="flex items-center blue-color">
//             {" "}
//             <BiEditAlt className="text-xl" /> Edit
//           </span>
//         </div>

//         <div className="border-b border-[#DBDBDB]">
//           <span className="text-[#5E5E5E]">I represent a company</span>
//           <p className="font-[510]">
//             {formData?.companyRepresentative ? "Yes" : "No"}
//           </p>

//           <span className="text-[#5E5E5E]">Full Name</span>
//           <p className="font-[510]">{formData?.fullname}</p>

//           <span className="text-[#5E5E5E]">Email ID</span>
//           <p className="font-[510]">{formData?.email}</p>

//           <span className="text-[#5E5E5E]">Country of Residence</span>
//           <p className="font-[510]">{formData?.country}</p>

//           <span className="text-[#5E5E5E]">Address</span>
//           <p className="font-[510]">{formData?.address}</p>
//         </div>

//         <div className="border-b border-[#DBDBDB]">
//           <div className="flex items-center justify-between mb-4 mt-[16px]">
//             <h3 className="md:text-[24px] text-[20px] font-[590]">Document</h3>
//             <span className="flex items-center blue-color">
//               {" "}
//               <BiEditAlt className="text-xl" /> Edit
//             </span>
//           </div>

//           <div className="pb-[16px]">
//             <span className="text-[#5E5E5E] block mb-2">Document Name</span>
//             <div className="inline-flex border-1 overflow-hidden rounded-md border-[#DBDBDB]">
//               <span className="bg-[#D9D9D9] p-2 flex items-center">
//                 <FaRegFileAlt />
//               </span>
//               <span className="border-r border-[#DBDBDB] p-2">
//                 {formData?.documentName}
//               </span>
//               <span className="p-2">View</span>
//             </div>
//           </div>
//         </div>

//         <div className="flex items-center justify-between mt-[16px] pb-[16px]">
//           <h3 className="md:text-[24px] text-[20px] font-[590]">
//             Withdrawal method
//           </h3>
//           <span className="flex items-center blue-color">
//             {" "}
//             <BiEditAlt className="text-xl" /> Edit
//           </span>
//         </div>

//         {formData?.withdrawalMethod === "bankTransfer" && (
//           <div>
//             <span className="text-[#5E5E5E]">Withdrawal Method</span>
//             <p className="font-[510]">Bank Transfer/SWIFT</p>

//             <span className="text-[#5E5E5E]">Beneficiary Name</span>
//             <p className="font-[510]">{formData?.beneficiaryName}</p>

//             <span className="text-[#5E5E5E]">IBAN/Account No.</span>
//             <p className="font-[510]">{formData?.iban}</p>

//             <span className="text-[#5E5E5E]">SWIFT Code</span>
//             <p className="font-[510]">{formData?.swiftCode}</p>

//             <span className="text-[#5E5E5E]">Bank Name</span>
//             <p className="font-[510]">{formData?.bankName}</p>
//           </div>
//         )}

//         {formData?.withdrawalMethod === "cryptocurrency" && (
//           <div>
//             <span className="text-[#5E5E5E]">Withdrawal Method</span>
//             <p className="font-[510]">Cryptocurrency</p>

//             <span className="text-[#5E5E5E]">Token Type</span>
//             <p className="font-[510]">{formData?.tokenType}</p>

//             <span className="text-[#5E5E5E]">Wallet Address</span>
//             <p className="font-[510]">{formData?.walletAddress}</p>
//           </div>
//         )}

//         <button
//           className="bg-black-color block no-underline text-center leading-[46px] px-[20px] text-white rounded-lg md:w-[270px] w-full h-[46px] mt-[100px]"
//           onClick={handleSubmit}
//         >
//           Next
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Review;

import React, { useEffect, useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { FaRegFileAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { submitForm, updateFormData } from "../../redux/actions/auth.action";
import IdentificationDetails from "../account-varification/IdentificationDetails";
import WithdrawalOption from "../account-varification/WithdrawalOption";
import { useNavigate } from "react-router-dom";

const Review = () => {
  const dispatch = useDispatch();
  const { formData } = useSelector((state) => state.authentication);

  const [images, setImages] = useState([]);
  const [preview, setPreview] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    setImages(formData?.files);
  }, [formData]);

  const [editMode, setEditMode] = useState(false);
  const [editWithdrawalMode, setEditWithdrawalMode] = useState(false);
  const navigate = useNavigate();

  const handleIdentificationEdit = () => {
    setEditMode(true);
  };

  const handleWithdrawalModeinEdit = () => {
    setEditWithdrawalMode(true);
  };

  const handleNext = (updatedData) => {
    dispatch(updateFormData(updatedData));
    setEditMode(false);
    setEditWithdrawalMode(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitLoading(true);
    dispatch(submitForm(formData)).then(() => {
      navigate("/congratulation");
      setSubmitLoading(false);
    });
  };

  const handleImageClick = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div className="identification_details bg-white border-1 border-[#DBDBDB] rounded-lg py-3 md:px-[30px] px-[16px]">
      <h3 className="md:text-[20px] text-base font-[590]">Review</h3>
      <p className="mb-5">
        Your details will be used for billing and generating invoice
      </p>

      {!editMode ? (
        <>
          {/* Display review information */}
          <div className="flex items-center justify-between mb-4">
            <h3 className="md:text-[24px] text-[20px] font-[590]">
              Identification Details
            </h3>
            <span
              className="flex items-center blue-color"
              onClick={handleIdentificationEdit}
            >
              <BiEditAlt className="text-xl" /> Edit
            </span>
          </div>
          <div className="border-b border-[#DBDBDB]">
            <span className="text-[#5E5E5E]">I represent a company</span>
            <p className="font-[510]">{formData?.isCompany ? "Yes" : "No"}</p>

            <span className="text-[#5E5E5E]">Full Name</span>
            <p className="font-[510]">{formData?.fullname}</p>

            <span className="text-[#5E5E5E]">Email ID</span>
            <p className="font-[510]">{formData?.email}</p>

            <span className="text-[#5E5E5E]">Country of Residence</span>
            <p className="font-[510]">{formData?.country}</p>

            <span className="text-[#5E5E5E]">Address</span>
            <p className="font-[510]">{formData?.address}</p>

            {formData?.isCompany && (
              <>
                <span className="text-[#5E5E5E]">Company Name</span>
                <p className="font-[510]">{formData?.companyName}</p>
                <span className="text-[#5E5E5E]">CompanyEmail</span>
                <p className="font-[510]">{formData?.companyEmail}</p>
              </>
            )}
          </div>
        </>
      ) : (
        <IdentificationDetails
          initialData={formData}
          handleNext={handleNext}
          setEditMode={setEditMode}
        />
      )}

      <div>
        <div className="border-b border-[#DBDBDB]">
          <div className="flex items-center justify-between mb-4 mt-[16px]">
            <h3 className="md:text-[24px] text-[20px] font-[590]">Document</h3>
            <span className="flex items-center blue-color">
              <BiEditAlt className="text-xl" /> Edit
            </span>
          </div>

          <div className="pb-[16px]">
            <span className="text-[#5E5E5E] block mb-2">Document Name</span>
            <div className="inline-flex border-1 overflow-hidden rounded-md border-[#DBDBDB]">
              <span className="bg-[#D9D9D9] p-2 flex items-center">
                <FaRegFileAlt />
              </span>
              <span className="border-r border-[#DBDBDB] p-2">
                {formData?.documentName}
              </span>
              <button
                className="p-2"
                onClick={() => {
                  setPreview(!preview);
                }}
              >
                {!preview ? "View" : "Hide"}
              </button>
            </div>
          </div>
          <div className="flex gap-2 mb-2">
            {preview &&
              images.map((image, index) => {
                return (
                  <img
                    key={index}
                    src={image?.preview}
                    alt="preview"
                    className="w-[60px] rounded-md gap-2 cursor-pointer border h-[50px]"
                    onClick={() => handleImageClick(image?.preview)}
                  />
                );
              })}
          </div>
        </div>
        {formData?.userType === "publisher" &&
          (!editWithdrawalMode ? (
            <>
              <div className="flex items-center justify-between mt-[16px] pb-[16px]">
                <h3 className="md:text-[24px] text-[20px] font-[590]">
                  Withdrawal method
                </h3>
                <span
                  className="flex items-center blue-color"
                  onClick={handleWithdrawalModeinEdit}
                >
                  <BiEditAlt className="text-xl" /> Edit
                </span>
              </div>

              {formData?.withdrawalOption === "bank" && (
                <div>
                  <span className="text-[#5E5E5E]">Withdrawal Method</span>
                  <p className="font-[510]">Bank Transfer/SWIFT</p>

                  <span className="text-[#5E5E5E]">Beneficiary Name</span>
                  <p className="font-[510]">{formData?.beneficiaryName}</p>

                  <span className="text-[#5E5E5E]">IBAN/Account No.</span>
                  <p className="font-[510]">{formData?.ibanNo}</p>

                  <span className="text-[#5E5E5E]">SWIFT Code</span>
                  <p className="font-[510]">{formData?.swiftCode}</p>

                  <span className="text-[#5E5E5E]">Bank Name</span>
                  <p className="font-[510]">{formData?.bankName}</p>
                </div>
              )}

              {formData?.withdrawalOption === "crpto" && (
                <div>
                  <span className="text-[#5E5E5E]">Withdrawal Method</span>
                  <p className="font-[510]">Cryptocurrency</p>

                  <span className="text-[#5E5E5E]">Token Type</span>
                  <p className="font-[510]">{formData?.tokenType}</p>

                  <span className="text-[#5E5E5E]">Wallet Address</span>
                  <p className="font-[510]">{formData?.walletAddress}</p>
                </div>
              )}
            </>
          ) : (
            <WithdrawalOption
              initialData={formData}
              handleNext={handleNext}
              setEditMode={editWithdrawalMode}
            />
          ))}

        {formData && !formData?.isAdminVerified && (
          <button
            className={`bg-black-color block no-underline text-center leading-[46px] px-[20px] text-white rounded-lg md:w-[270px] w-full h-[46px] mt-[100px] ${
              submitLoading && "flex justify-center items-center"
            }`}
            onClick={handleSubmit}
            disabled={submitLoading}
          >
            {submitLoading ? <div className="loader"></div> : "Submit"}
          </button>
        )}
      </div>
    </div>
  );
};

export default Review;
