import React, { useEffect, useState } from "react";
import { IoIosCheckmark } from "react-icons/io";
import { FaSquarePlus } from "react-icons/fa6";
import BannerExample1 from "./BannerExample1";
// import { BASEURL } from "../../baseUrl";
// import axios from "axios";
import useAddCart from "../../hooks/useAddCart";
import { authApi } from "../../api";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PopluarPackage = ({ isAdvertiser, selectedPackage }) => {
  const { addToCart, loading } = useAddCart();
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);
  
  const fetchPackages = async () => {
    try {
      const response = await authApi(`packages/my`, {
        params: {
          status: "approved",
        },
      });

      setPackages(response?.data?.packageList);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPackages();
  }, []);

  const isAuthenticated = useSelector(
    (state) => state.authentication.isAuthenticated
  );

  const handleAddToCart = async (itemId) => {
    try {
      await addToCart("packages", itemId);
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  

  return (
    <div className="bg-white shadows mb-4 p-4 rounded-lg border-1 border-[#DBDBDB]">
      <h3 className="md:text-[24px] text-[20px] font-[590] mb-4">
        Popular packages
      </h3>
      <div className="row row-cols-1 row-cols-md-3 g-4">
        {packages?.length > 0 ? (
          packages.map(
            (plan) =>
              (
                <div key={plan?._id} className="col">
                  <div className="card h-100 bg-white border-1 border-[#DBDBDB] shadow-[0px_4px_28px_#0000001C] rounded-lg overflow-hidden">
                    <div className="bg-[#F5F5F5] p-2 d-flex justify-between items-center">
                      <span className="font-[590] capitalize text-[13px]">
                        {plan?.name}
                      </span>
                      <span className="font-[590]">${plan?.totalAmount}</span>
                    </div>
                    <div className="p-2 card-body">
                      <img
                        src="/assets/images/expanded/newslogo.png"
                        alt="newslogo"
                        className="w-[32px] mb-2"
                      />
                      <p className="text-[13px]">{plan?.additionalDetails}</p>
                      <h3 className="text-base font-[590]">Includes</h3>
                      <ul>
                        {plan?.offeringCategoryId?.offeringTypeList?.map(
                          (item) => (
                            <li key={item?._id} className="flex items-center">
                              <IoIosCheckmark />
                              <span className="text-[13px] font-[500]">
                                {item?.name}
                              </span>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                    <div className="bg-[#F5F5F5] card-footer border-0 p-2 d-flex justify-between items-center">
                      {isAdvertiser && (
                        <span className="font-[590] text-[13px] flex items-center gap-1">
                          Add to cart
                          <FaSquarePlus
                            className="text-2xl  cursor-pointer  "
                            onClick={() => {
                              if (!isAuthenticated) {
                                navigate("/sign-in");
                                return;
                              }
                              if (!loading) {
                                handleAddToCart(plan?._id);
                              }
                            }}
                            disabled={loading}
                          />
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )
          )
        ) : (
          <div className="flex ">
            <p className="text-center text-lg ">No package found</p>
          </div>
        )}
      </div>

      {/* <BannerExample1 /> */}
    </div>
  );
};

export default PopluarPackage;
