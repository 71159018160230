import React from "react";
import Index from "../../Components/expanded-view/Index";
import Header from "../../layout/headers/header";

const ExpandedView = () => {
  return (
    <div>
      <Header />
      <Index />
    </div>
  );
};

export default ExpandedView;
